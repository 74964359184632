import {FC, HTMLAttributes} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  type?: 'border' | 'grow'
  size?: 'sm' | 'lg' | undefined
  color?:
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'info'
    | 'success'
    | 'danger'
    | 'dark'
    | 'light'
    | undefined
  className?: string
}

const Spinner: FC<Props> = ({type = 'grow', size, color, className = '', ...otherProp}) => {
  return (
    <div
      className={`spinner-${type} ${color ? `text-${color}` : ''} ${
        size ? `spinner-${type}-${size}` : ''
      } ${className}`}
      role='status'
      {...otherProp}
    >
      <span className='visually-hidden'>Loading...</span>
    </div>
  )
}

export default Spinner
