import {
  ADD_ROLE_MODULE_PERMISSIONS,
  GET_ROLE_MODULE_PERMISSIONS_BY_LEVEL,
  DELETE_ROLE_MODULE_PERMISSIONS_BY_LEVEL,
} from '../../Actions/RoleModulePermissions/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(ADD_ROLE_MODULE_PERMISSIONS, fetchData)
  yield takeEvery(GET_ROLE_MODULE_PERMISSIONS_BY_LEVEL, fetchData)
  yield takeEvery(DELETE_ROLE_MODULE_PERMISSIONS_BY_LEVEL, fetchData)
}

export default dataSaga
