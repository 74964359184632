import {ErrorMessage, useFormikContext} from 'formik'
import {ChangeEventHandler, FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  name: string
  label?: string
  value: any
  onChange?: ChangeEventHandler<HTMLInputElement>
  accept?: string
  className?: string
  required?: boolean
  showError?: boolean
  multiple?: boolean
  hideUploadOnSelect?: boolean
  hideDownload?: boolean
  showLabel?: boolean
}

const CustomDownload: FC<{value: any}> = ({value}) => {
  let fileName = typeof value === 'string' ? value.split('/').slice(-1).pop() : value?.name
  if (fileName && fileName?.length > 7) {
    let splitFileName = fileName.split('.')

    fileName = splitFileName[0].substring(0, 2) + '...' + splitFileName[1]
  }
  const fullFileName = typeof value === 'string' ? value.split('/').slice(-1).pop() : value?.name
  if (value)
    return (
      <a
        className={`btn btn-primary btn-sm px-6 align-self-center text-nowrap ${
          fileName ? '' : 'btn-icon'
        } mb-1 me-1`}
        href={
          typeof value === 'string'
            ? value
            : window.URL.createObjectURL(new Blob([value], {type: value.type}))
        }
        target='_blank'
        download={fullFileName}
        title={fullFileName}
        rel='noreferrer'
      >
        <span className='fas fa-solid fa-download' /> {fileName}
      </a>
    )
  else return null
}

const CustomFileFiled: FC<Props> = ({
  name,
  label = '',
  className = '',
  value,
  onChange = null,
  required = false,
  showError = true,
  accept,
  multiple = false,
  hideUploadOnSelect = false,
  hideDownload = false,
  showLabel = false,
}) => {
  const intl = useIntl()
  const id = `${name}-${Math.random()}`

  const formik = useFormikContext()

  const hasTranslation = label ? !!intl.messages[label] : false

  const handleChange = (e: any) => {
    const files = multiple ? e.target.files : e.target.files[0]

    formik.setFieldValue(name, files)

    if (onChange) onChange(e)
  }

  return (
    <div className={`mb-3 ${className}`}>
      {(!value || !hideUploadOnSelect) && (
        <>
          <input
            id={id}
            name={name}
            type='file'
            className='d-none'
            onChange={handleChange}
            accept={accept}
            multiple={multiple}
          />
          <label
            className={`btn btn-primary btn-sm ${label ? '' : 'btn-icon'} ${
              value ? 'me-1 mb-1' : ''
            } ${required ? 'required' : ''}`}
            htmlFor={id}
            title={hasTranslation ? intl.formatMessage({id: label}) : label}
          >
            {showLabel && label && (hasTranslation ? intl.formatMessage({id: label}) : label)}
            <span className={`fas fa-solid fa-upload ${showLabel && label ? 'ms-1' : ''}`} />
          </label>
        </>
      )}

      {!hideDownload &&
        value &&
        (value instanceof FileList ? (
          Array.from(value).map((v, i) => <CustomDownload value={v} key={i} />)
        ) : Array.isArray(value) ? (
          value.map((v, i) => <CustomDownload value={v} key={i} />)
        ) : (
          <CustomDownload value={value} />
        ))}

      {showError && <ErrorMessage component='div' name={name} className='text-danger mt-2' />}
    </div>
  )
}

export default CustomFileFiled
