/* eslint-disable */
let mixed = {
  default: 'غلط ہے ${path}',
  required: 'کی ضرورت ہے ${path}',
  oneOf: '${values} :مندرجہ ذیل اقدار میں سے ایک ہونا ضروری ہے ${path}',
  notOneOf: '${values}:درج ذیل اقدار میں سے ایک نہیں ہونا چاہیے ${path} ',
  notType: ({path, type, value, originalValue}) => {
    // let isCast = originalValue != null && originalValue !== value
    let msg = `${path} must be a \`${type}\` type`
    //   + `but the final value was: \`${(0, _printValue.default)(value, true)}\`` +
    //   (isCast
    //     ? ` (cast from the value \`${(0, _printValue.default)(originalValue, true)}\`).`
    //     : '.')

    // if (value === null) {
    //   msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``
    // }

    return msg
  },
  defined: 'کی وضاحت ضروری ہے ${path}',
}

let string = {
  length: 'حروف کا ہونا چاہیے ${length} بالکل ${path}',
  min: 'حروف کا ہونا چاہیے ${min} کم از کم ${path}',
  max: 'حروف کا ہونا چاہیے ${max} زیادہ سے زیادہ ${path}',
  matches: '"${regex}":درج ذیل سے مماثل ہونا چاہیے ${path}',
  email: 'ایک درست ای میل ہونا ضروری ہے ${path}',
  url: 'ہونا چاہیے URL ایک درست ${path}',
  uuid: 'ہونا ضروری ہے UUID ایک درست ${path}',
  trim: ' ایک تراشی ہوئی سٹرنگ ہونی چاہیے ${path}',
  lowercase: 'ایک لوئر کیس سٹرنگ ہونا چاہیے ${path}',
  uppercase: 'ایک اوپری کیس سٹرنگ ہونی چاہیے ${path}',
}

let number = {
  min: ' سے بڑا یا اس کے برابر ہونا چاہیے ${min} ${path}',
  max: 'سے کم یا اس کے برابر ہونا چاہیے ${max} ${path}',
  lessThan: 'سے کم ہونا چاہیے${less} ${path}',
  moreThan: 'سے بڑا ہونا چاہیے ${more} ${path}',
  positive: ' ایک مثبت نمبر ہونا چاہیے ${path}',
  negative: 'ایک منفی نمبر ہونا چاہیے ${path}',
  integer: 'ایک عدد صحیح ہونا چاہیے ${path}',
}

let date = {
  min: 'سے بعد کی ہونی چاہیے ${min} فیلڈ ${path}',
  max: 'سے پہلے کی ہونی چاہیے ${max} فیلڈ ${path}',
}

let boolean = {
  isValue: 'ہونا چاہیے ${value}فیلڈ ${path}',
}

let object = {
  noUnknown: ' ${unknown}:فیلڈ میں غیر متعینہ کلیدیں ہیں ${path}',
}

let array = {
  min: 'آئٹمز ہونے چاہئیں ${min} فیلڈ میں کم از کم ${path}',
  max: ' آئٹمز سے کم یا اس کے برابر ہونا ضروری ہے ${max} فیلڈ میں ${path}',
  length: 'آئٹمز ہونے چاہئیں ${length} میں ${path}',
}

const ur = Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
})

export default ur
