import {GET_SMS_LOGS, GET_SMS_LOG} from 'setup/redux/Actions/SmsLog/components/ApiActionTypes'

import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  // Category
  yield takeEvery(GET_SMS_LOGS, fetchData)
  yield takeEvery(GET_SMS_LOG, fetchData)
}

export default dataSaga
