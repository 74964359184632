import {takeEvery} from 'redux-saga/effects'
import {
  GET_TRANSACTION,
  GET_TRANSACTIONS,
} from 'setup/redux/Actions/Transaction/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_TRANSACTIONS, fetchData)
  yield takeEvery(GET_TRANSACTION, fetchData)
}

export default dataSaga
