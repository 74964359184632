import {
  GET_HOMEWORKS,
  GET_HOMEWORK_DETAIL,
  GET_HOMEWORKS_MESSAGES,
  SEND_HOMEWORK_MESSAGE,
  GET_SIGNED_UPLOAD_URL,
  CONFIRM_FILE_UPLOAD,
  SUBMIT_HOMEWORK,
  DELETE_HOMEWORK_FILE,
} from '../../Actions/Homework/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_HOMEWORKS, fetchData)
  yield takeEvery(GET_HOMEWORK_DETAIL, fetchData)
  yield takeEvery(GET_HOMEWORKS_MESSAGES, fetchData)
  yield takeEvery(SEND_HOMEWORK_MESSAGE, fetchData)
  yield takeEvery(GET_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(CONFIRM_FILE_UPLOAD, fetchData)
  yield takeEvery(DELETE_HOMEWORK_FILE, fetchData)
  yield takeEvery(SUBMIT_HOMEWORK, fetchData)
}

export default dataSaga
