import {
  GET_SECTIONS,
  GET_SECTION,
  DELETE_SECTION,
  ADD_SECTION,
  DELETE_MANY_SECTIONS,
  UPDATE_SECTION,
} from '../../../Actions/School/Section/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_SECTIONS, fetchData)
  yield takeEvery(GET_SECTION, fetchData)
  yield takeEvery(DELETE_SECTION, fetchData)
  yield takeEvery(ADD_SECTION, fetchData)
  yield takeEvery(DELETE_MANY_SECTIONS, fetchData)
  yield takeEvery(UPDATE_SECTION, fetchData)
}

export default dataSaga
