export const GET_SCHOOL_LECTURES = 'GET_SCHOOL_LECTURES'
export const GET_SCHOOL_LECTURE = 'GET_SCHOOL_LECTURE_DETAIL'
export const DELETE_SCHOOL_LECTURE = 'DELETE_SCHOOL_LECTURE'
export const UPDATE_SCHOOL_LECTURE = 'UPDATE_SCHOOL_LECTURE'
export const ADD_SCHOOL_LECTURE = 'ADD_SCHOOL_LECTURE'
export const GET_SCHOOL_LECTURE_ONLINE_USER = 'GET_SCHOOL_LECTURE_ONLINE_USER'
export const ADD_SCHOOL_LECTURE_COMMENT = 'ADD_SCHOOL_LECTURE_COMMENT'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS'
