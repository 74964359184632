import Spinner from 'common/components/Spinner'
import {FC, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {RootState, UserReducer} from 'setup'
import {REDUX_SAVE_LOGIN_INFO} from 'setup/redux/Actions/SignIn/ActionTypes/ReduxActionTypes'
import {loginWithJWT} from 'setup/redux/Actions/SignIn/login'

export const Auth: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userReducer: UserReducer = useSelector<RootState>(
    ({userReducer}) => userReducer,
    shallowEqual
  ) as UserReducer

  const accessToken: string = userReducer.accessToken as string

  useEffect(() => {
    const authToken = searchParams.get('auth_token')
    if (authToken) {
      const onSuccess = (res: any) => {
        dispatch({
          type: REDUX_SAVE_LOGIN_INFO,
          payload: {
            result: res.result,
          },
        })

        let path = searchParams.get('path')
        if (path) {
          if (!path.startsWith('/')) {
            path = `/${path}`
          }
          if (path.startsWith('/school')) {
            path = path.replace('/school', '/institute')
          }
          navigate(path)
        } else {
          navigate('/')
        }
      }

      const onFailure = (err: any) => {
        const message =
          err?.response?.data?.message ??
          err?.response?.message ??
          err?.message ??
          'Invalid Auth Token'
        toast.error(message)
        navigate('/auth/login')
      }

      dispatch(loginWithJWT(authToken, onSuccess, onFailure))
    } else if (accessToken) {
      navigate('/')
    } else {
      navigate('/auth/login')
    }
  }, [accessToken, dispatch, navigate, searchParams])
  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <Spinner type='grow' size='lg' color='primary' />
    </div>
  )
}
