import {GET_WHATSAPP_LOGS} from 'setup/redux/Actions/WhatsappLog/ActionTypes/ApiActionTypes'

import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_WHATSAPP_LOGS, fetchData)
}

export default dataSaga
