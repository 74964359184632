import {Suspense, useEffect, useState} from 'react'
import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {I18nProvider} from 'common/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from 'common/layout/core'
import {MasterInit} from 'common/layout/MasterInit'

import {RootState} from 'setup'
import {useDispatch, useSelector} from 'react-redux'
import {changeFavicon} from 'common/HelperFunctions'
import {toast} from 'react-toastify'
import Spinner from 'common/components/Spinner'
import {loginWithJWT} from 'setup/redux/Actions/SignIn/login'

import '_metronic/assets/sass/style.react.scss'

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [loggingIn, setLoggingIn] = useState(false)

  const generalSettings = useSelector<RootState>(
    ({mainReducer}) => mainReducer.generalSettings
  ) as any

  if (generalSettings?.favicon) {
    changeFavicon(generalSettings.favicon)
  }

  useEffect(() => {
    let search = searchParams.toString()
    if (search) {
      search = `?${search}`
    }

    const authToken = searchParams.get('rauth_token')
    if (authToken) {
      setLoggingIn(true)

      const onSuccess = () => {
        setLoggingIn(false)
        if (location.pathname) {
          setTimeout(() => {
            navigate({pathname: location.pathname, search})
          }, 500)
        }
      }

      const onFailure = (err: any) => {
        const message =
          err?.response?.data?.message ??
          err?.response?.message ??
          err?.message ??
          'Invalid Auth Token'
        toast.error(message)
      }

      dispatch(loginWithJWT(authToken, onSuccess, onFailure))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loggingIn) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center h-100'>
        <Spinner size='lg' color='primary' />
      </div>
    )
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <Outlet />
          <MasterInit />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
