import {
  GET_NEWS_LIST,
  DELETE_NEWS_BY_ID,
  GET_NEWS_BY_ID,
  DELETE_NEWS_IN_BULK,
  ADD_NEWS,
  UPDATE_NEWS,
  NEWS_REPORT,
  SEND_NEWS_NOTIFICATION,
  NEWS_APPROVAL_STATUS,
  NEWS_APPROVAL_INFO,
  SEND_NEWS_SMS,
  DELETE_NEWS_COVER_BY_ID,
  DELETE_NEWS_ATTACHMENT_BY_ID,
} from '../../../Actions/News/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_NEWS_LIST, fetchData)
  yield takeEvery(DELETE_NEWS_BY_ID, fetchData)
  yield takeEvery(GET_NEWS_BY_ID, fetchData)
  yield takeEvery(DELETE_NEWS_IN_BULK, fetchData)
  yield takeEvery(UPDATE_NEWS, fetchData)
  yield takeEvery(ADD_NEWS, fetchData)
  yield takeEvery(NEWS_REPORT, fetchData)
  yield takeEvery(SEND_NEWS_NOTIFICATION, fetchData)
  yield takeEvery(SEND_NEWS_SMS, fetchData)
  yield takeEvery(NEWS_APPROVAL_STATUS, fetchData)
  yield takeEvery(NEWS_APPROVAL_INFO, fetchData)
  yield takeEvery(DELETE_NEWS_COVER_BY_ID, fetchData)
  yield takeEvery(DELETE_NEWS_ATTACHMENT_BY_ID, fetchData)
}

export default dataSaga
