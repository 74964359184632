import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'
import {
  GET_ALL_USER_STAFF,
  SMS_STAFF_LOGIN_DETAILS,
  GENERATE_STAFF_LOGIN_REPORT,
  EMAIL_STAFF_LOGIN_DETAILS,
  GENERATE_STAFF_LIST_REPORT_PDF,
} from 'setup/redux/Actions/Staff/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_ALL_USER_STAFF, fetchData)
  yield takeEvery(EMAIL_STAFF_LOGIN_DETAILS, fetchData)
  yield takeEvery(GENERATE_STAFF_LOGIN_REPORT, fetchData)
  yield takeEvery(SMS_STAFF_LOGIN_DETAILS, fetchData)
  yield takeEvery(GENERATE_STAFF_LIST_REPORT_PDF, fetchData)
}

export default dataSaga
