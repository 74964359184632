// Category
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_ACTIVE_CATEGORIES = 'GET_ACTIVE_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

// Subject
export const GET_BOOK_SUBJECTS = 'GET_BOOK_SUBJECTS'
export const GET_ACTIVE_SUBJECTS = 'GET_ACTIVE_SUBJECTS'
export const GET_BOOK_SUBJECT = 'GET_BOOK_SUBJECT'
export const ADD_SUBJECT = 'ADD_SUBJECT'
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT'
export const DELETE_SUBJECT = 'DELETE_SUBJECT'

// Book
export const GET_BOOKS = 'GET_BOOKS'
export const GET_ACTIVE_BOOKS = 'GET_ACTIVE_BOOKS'
export const GET_BOOK = 'GET_BOOK'
export const ADD_BOOK = 'ADD_BOOK'
export const UPDATE_BOOK = 'UPDATE_BOOK'
export const DELETE_BOOK = 'DELETE_BOOK'
export const DELETE_BOOKS = 'DELETE_BOOKS'
export const FILE_UPLOAD_BOOK = 'FILE_UPLOAD_BOOK'

// Book
export const GET_BOOK_RESERVATIONS = 'GET_BOOK_RESERVATIONS'
export const GET_BOOK_RESERVATIONS_HISTORY = 'GET_BOOK_RESERVATIONS_HISTORY'
export const GET_BOOK_RESERVATION = 'GET_BOOK_RESERVATION'
export const ADD_BOOK_RESERVATION = 'ADD_BOOK_RESERVATION'
export const UPDATE_BOOK_RESERVATION = 'UPDATE_BOOK_RESERVATION'
export const DELETE_BOOK_RESERVATION = 'DELETE_BOOK_RESERVATION'

export const BOOK_RETURN = 'BOOK_RETURN'
export const SEND_MAIL_RESERVATION = 'SEND_MAIL_RESERVATION'

export const GET_USER_RESERVATIONS = 'GET_USER_RESERVATIONS'

// Setting
export const GET_BOOK_SETTINGS = 'GET_BOOK_SETTINGS'
export const GET_BOOK_SETTING = 'GET_BOOK_SETTING'
export const UPDATE_BOOK_SETTING = 'UPDATE_BOOK_SETTING'

// Renewal Request
export const GET_BOOK_RENEWAL_REQUEST = 'GET_BOOK_RENEWAL_REQUEST'
export const UPDATE_BOOK_RENEWAL_REQUEST = 'UPDATE_BOOK_RENEWAL_REQUEST'

export const LIBRARY_STATS = 'LIBRARY_STATS'
