export const GET_STUDENT_LIST = 'GET_STUDENT_LIST'
export const UPDATE_STUDENT = 'UPDATE_STUDENT'
export const ADD_STUDENT = 'ADD_STUDENT'
export const ADD_SIBLINGS = 'ADD_SIBLINGS'
export const GET_STUDENT_BY_ID = 'GET_STUDENT_BY_ID'
export const GET_PARENT_BY_ID = 'GET_PARENT_BY_ID'
export const GET_SIBLING_INFO = 'GET_SIBLING_INFO'
export const GET_STD_ACADEMIC_RECORD = 'GET_STD_ACADEMIC_RECORD'
export const GET_STD_EXAM_ACADEMIC_RECORD = 'GET_STD_EXAM_ACADEMIC_RECORD'
export const GET_STD_PREV_EXAM_ACADEMIC_RECORD = 'GET_STD_PREV_EXAM_ACADEMIC_RECORD'
export const GET_STD_FEE_RECORD = 'GET_STD_FEE_RECORD'
export const GET_STD_ALL_SPORTS = 'GET_STD_ALL_SPORTS'
export const GET_STD_REMARKS_SPORTS = 'GET_STD_REMARKS_SPORTS'
export const GET_STD_GENERAL_REMARKS = 'GET_STD_GENERAL_REMARKS'
export const GET_STD_SUBJECTS_REMARKS = 'GET_STD_SUBJECTS_REMARKS'
export const UPDATE_STD_GENERAL_REMARKS = 'UPDATE_STD_GENERAL_REMARKS'
export const UPDATE_STD_SUBJECTS_REMARKS = 'UPDATE_STD_SUBJECTS_REMARKS'
export const ADD_STD_SUBJECTS_REMARKS = 'ADD_STD_SUBJECTS_REMARKS'
export const ADD_STD_GENERAL_REMARKS = 'ADD_STD_GENERAL_REMARKS'
export const GET_STD_USER_ID = 'GET_STD_USER_ID'
export const ADD_STD_SPORTS = 'ADD_STD_SPORTS'
export const ADD_STD_SPORTS_BY_ID = 'ADD_STD_SPORTS_BY_ID'
export const GET_STD_LATEST_STD_NO = 'GET_STD_LATEST_STD_NO'
export const GET_STD_HW = 'GET_STD_HW'
export const GET_STD_TEST = 'GET_STD_TEST'
export const GET_STD_EXAM = 'GET_STD_EXAM'
export const GET_STD_UNHW = 'GET_STD_UNHW'
export const GET_STD_UNTEST = 'GET_STD_UNTEST'
export const GET_STD_UNEXAM = 'GET_STD_UNEXAM'
export const ASSIGN_HW_TEST_EXAM = 'ASSIGN_HW_TEST_EXAM'
export const GET_HW_TEST_EXAM = 'GET_HW_TEST_EXAM'
export const DEL_HW_TEST_EXAM = 'DEL_HW_TEST_EXAM'
export const GET_STD_SUBJECTS = 'GET_STD_SUBJECTS'
export const DEL_STD_SUBJECTS_REMARKS = '  DEL_STD_SUBJECTS_REMARKS'
export const DEL_STD_GENERAL_REMARKS = 'DEL_STD_GENERAL_REMARKS'
export const ASSIGN_HW = 'ASSIGN_HW'
export const ASSIGN_TEST = 'ASSIGN_TEST'
export const ASSIGN_EXAM = 'ASSIGN_EXAM'
export const GET_FILE_TYPES = 'GET_FILE_TYPES'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const DEL_STD_HOMEWORK = 'DEL_STD_HOMEWORK'
export const DEL_STD_TEST = 'DEL_STD_TEST'
export const DEL_STD_EXAM = 'DEL_STD_EXAM'
export const GET_UPLOADED_FILE = 'GET_UPLOADED_FILE'
export const DELETE_UPLOADED_FILE = 'DELETE_UPLOADED_FILE'
export const ADD_STD_SPORTS_REMARKS = 'ADD_STD_SPORTS_REMARKS'
export const GET_STD_HW_ACADEMIC_RECORD = 'GET_STD_HW_ACADEMIC_RECORD'
export const GET_STD_TEST_ACADEMIC_RECORD = 'GET_STD_TEST_ACADEMIC_RECORD'
export const GET_NEW_ADMISSION_STUDENT = 'GET_NEW_ADMISSION_STUDENT'
export const GET_PREVIOUS_CLASS_HISTORY = 'GET_PREVIOUS_CLASS_HISTORY'
export const CREATE_PREVIOUS_CLASS_HISTORY = 'CREATE_PREVIOUS_CLASS_HISTORY'
export const GENERATE_STUDENT_LIST_REPORT = 'GENERATE_STUDENT_LIST_REPORT'
export const GENERATE_STUDENT_LOGIN_REPORT = 'GENERATE_STUDENT_LOGIN_REPORT'
export const GET_STUDENT_DROPOUT_PDF = 'GET_STUDENT_DROPOUT_PDF'
export const GET_MONTH_WISE_STUDENT_DROPOUT_PDF = 'GET_MONTH_WISE_STUDENT_DROPOUT_PDF'
export const SMS_LOGIN_DETAILS = 'SMS_LOGIN_DETAILS'
export const EMAIL_LOGIN_DETAILS = 'EMAIL_LOGIN_DETAILS'
export const GET_DONARS_LIST = 'GET_DONARS_LIST'
export const GET_ACADEMIC_RECORD = 'GET_ACADEMIC_RECORD'
export const GET_COUNSELLOR_MENTORS = 'GET_COUNSELLOR_MENTORS'
export const GET_STUDENT_SUBJECT_EXAMS = 'GET_STUDENT_SUBJECT_EXAMS'
