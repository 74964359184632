import {takeEvery} from 'redux-saga/effects'
import {
  GET_ADMISSION_STAGE,
  GET_ADMISSION_STAGES,
} from 'setup/redux/Actions/AdmissionStage/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_ADMISSION_STAGES, fetchData)
  yield takeEvery(GET_ADMISSION_STAGE, fetchData)
}

export default dataSaga
