import {
  GET_LEVEL,
  GET_LEVELS,
  ADD_LEVEL,
  UPDATE_LEVEL,
  VIEW_LEVEL,
  DELETE_LEVEL,
} from 'setup/redux/Actions/Level/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_LEVELS, fetchData)
  yield takeEvery(GET_LEVEL, fetchData)
  yield takeEvery(ADD_LEVEL, fetchData)
  yield takeEvery(UPDATE_LEVEL, fetchData)
  yield takeEvery(VIEW_LEVEL, fetchData)
  yield takeEvery(DELETE_LEVEL, fetchData)
}

export default dataSaga
