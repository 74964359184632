import {GET_LECTURES, GET_LECTURE_DETAIL} from '../../Actions/Lectures/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_LECTURES, fetchData)
  yield takeEvery(GET_LECTURE_DETAIL, fetchData)
}

export default dataSaga
