// import {toast} from 'react-toastify'
import {REDUX_SAVE_NOTIFICATIONS_COUNT} from '../Actions/Notifications/ActionTypes/ReduxActionTypes'

const initialState = {
  notificationsCount: 0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_SAVE_NOTIFICATIONS_COUNT:
      const {
        result: {count},
      } = action.payload
      // if (count > state.notificationsCount) {
      //   toast.info(`You have ${count} new notifications`)
      // }
      return {
        ...state,
        notificationsCount: count,
      }
    default:
      return state
  }
}

export default reducer
