import {useState} from 'react'
import * as Yup from 'yup'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {Form, Formik, FormikValues} from 'formik'
import {useDispatch} from 'react-redux'
import {resetPassword} from 'setup/redux/Actions/SignIn/forgotPassword'
import {toast} from 'react-toastify'
import CustomField from 'common/components/CustomField'
import Spinner from 'common/components/Spinner'

const resetPasswordSchema = Yup.object().shape({
  code: Yup.string().required(),
  password: Yup.string().label('Password').required(),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password must match')
    .label('Confirm Password')
    .required(),
})

export function ResetPassword() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const dispatch = useDispatch()

  const initialValues = {
    code: searchParams.get('code'),
    password: '',
    password_confirmation: '',
  }

  const handleSubmit = (values: typeof initialValues, actions: FormikValues) => {
    setHasErrors(undefined)

    const onSuccess = (res: any) => {
      actions.setSubmitting(false)
      toast.success('Password reset successfully')
      navigate('/auth/login')
    }

    const onFailure = (err: any) => {
      actions.setSubmitting(false)
      const message =
        err?.response?.data?.message ?? err?.message ?? 'Error requesting password reset'

      toast.error(message)
    }

    const body = {
      code: values.code ?? searchParams.get('code'),
      password: values.password,
      retypePassword: values.password_confirmation,
    }

    dispatch(resetPassword(body, onSuccess, onFailure))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Reset Password</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>Enter new password</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Password reset successfully.</div>
            </div>
          )}
          {/* end::Title */}

          <CustomField name='password' label='Password' type='password' />

          <CustomField name='password_confirmation' label='Confirm Password' type='password' />

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <Spinner size='sm' type='grow' className='me-1' />}
              <span className='indicator-label'>Submit</span>
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </Form>
      )}
    </Formik>
  )
}
