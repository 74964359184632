import {GET_LABEL, GET_LABELS} from 'setup/redux/Actions/Label/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_LABELS, fetchData)
  yield takeEvery(GET_LABEL, fetchData)
}

export default dataSaga
