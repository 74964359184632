import {
  GET_STUDENT_LIST,
  UPDATE_STUDENT,
  ADD_STUDENT,
  ADD_SIBLINGS,
  GET_STUDENT_BY_ID,
  GET_SIBLING_INFO,
  GET_STD_ACADEMIC_RECORD,
  GET_STD_FEE_RECORD,
  GET_STD_REMARKS_SPORTS,
  GET_STD_ALL_SPORTS,
  GET_STD_SUBJECTS_REMARKS,
  GET_STD_GENERAL_REMARKS,
  GET_PARENT_BY_ID,
  ADD_STD_SUBJECTS_REMARKS,
  ADD_STD_GENERAL_REMARKS,
  ADD_STD_SPORTS_REMARKS,
  UPDATE_STD_GENERAL_REMARKS,
  UPDATE_STD_SUBJECTS_REMARKS,
  ADD_STD_SPORTS,
  ADD_STD_SPORTS_BY_ID,
  GET_STD_LATEST_STD_NO,
  GET_STD_HW,
  GET_STD_TEST,
  GET_STD_EXAM,
  GET_STD_UNHW,
  GET_STD_UNTEST,
  GET_STD_UNEXAM,
  ASSIGN_HW_TEST_EXAM,
  ASSIGN_HW,
  ASSIGN_TEST,
  ASSIGN_EXAM,
  SMS_LOGIN_DETAILS,
  EMAIL_LOGIN_DETAILS,
  GET_HW_TEST_EXAM,
  DEL_HW_TEST_EXAM,
  GET_STD_SUBJECTS,
  DEL_STD_SUBJECTS_REMARKS,
  DEL_STD_GENERAL_REMARKS,
  GET_FILE_TYPES,
  UPLOAD_FILE,
  GET_UPLOADED_FILE,
  DELETE_UPLOADED_FILE,
  DEL_STD_HOMEWORK,
  DEL_STD_TEST,
  DEL_STD_EXAM,
  GET_STD_EXAM_ACADEMIC_RECORD,
  GET_STD_PREV_EXAM_ACADEMIC_RECORD,
  GET_STD_HW_ACADEMIC_RECORD,
  GET_STD_TEST_ACADEMIC_RECORD,
  GET_STD_USER_ID,
  GET_NEW_ADMISSION_STUDENT,
  GENERATE_STUDENT_LIST_REPORT,
  GENERATE_STUDENT_LOGIN_REPORT,
  GET_STUDENT_DROPOUT_PDF,
  GET_MONTH_WISE_STUDENT_DROPOUT_PDF,
  GET_PREVIOUS_CLASS_HISTORY,
  GET_DONARS_LIST,
  GET_ACADEMIC_RECORD,
  GET_STUDENT_SUBJECT_EXAMS,
  CREATE_PREVIOUS_CLASS_HISTORY,
  GET_COUNSELLOR_MENTORS,
} from '../../../Actions/School/Student/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_STUDENT_LIST, fetchData)
  yield takeEvery(GET_STD_ACADEMIC_RECORD, fetchData)
  yield takeEvery(UPDATE_STUDENT, fetchData)
  yield takeEvery(ADD_STUDENT, fetchData)
  yield takeEvery(GET_STUDENT_BY_ID, fetchData)
  yield takeEvery(GET_PARENT_BY_ID, fetchData)
  yield takeEvery(GET_SIBLING_INFO, fetchData)
  yield takeEvery(GET_STD_FEE_RECORD, fetchData)
  yield takeEvery(GET_STD_REMARKS_SPORTS, fetchData)
  yield takeEvery(GET_STD_ALL_SPORTS, fetchData)
  yield takeEvery(GET_STD_GENERAL_REMARKS, fetchData)
  yield takeEvery(GET_STD_SUBJECTS_REMARKS, fetchData)
  yield takeEvery(UPDATE_STD_GENERAL_REMARKS, fetchData)
  yield takeEvery(UPDATE_STD_SUBJECTS_REMARKS, fetchData)
  yield takeEvery(GET_STD_USER_ID, fetchData)
  yield takeEvery(ADD_SIBLINGS, fetchData)
  yield takeEvery(ADD_STD_SPORTS, fetchData)
  yield takeEvery(ADD_STD_SPORTS_BY_ID, fetchData)
  yield takeEvery(GET_STD_HW, fetchData)
  yield takeEvery(GET_STD_EXAM, fetchData)
  yield takeEvery(GET_STD_TEST, fetchData)
  yield takeEvery(GET_STD_UNHW, fetchData)
  yield takeEvery(GET_STD_UNEXAM, fetchData)
  yield takeEvery(GET_STD_UNTEST, fetchData)
  yield takeEvery(ASSIGN_HW_TEST_EXAM, fetchData)
  yield takeEvery(ASSIGN_HW, fetchData)
  yield takeEvery(ASSIGN_TEST, fetchData)
  yield takeEvery(ASSIGN_EXAM, fetchData)
  yield takeEvery(GET_HW_TEST_EXAM, fetchData)
  yield takeEvery(DEL_HW_TEST_EXAM, fetchData)
  yield takeEvery(GET_STD_LATEST_STD_NO, fetchData)
  yield takeEvery(GET_STD_SUBJECTS, fetchData)
  yield takeEvery(ADD_STD_SUBJECTS_REMARKS, fetchData)
  yield takeEvery(ADD_STD_GENERAL_REMARKS, fetchData)
  yield takeEvery(DEL_STD_SUBJECTS_REMARKS, fetchData)
  yield takeEvery(DEL_STD_GENERAL_REMARKS, fetchData)
  yield takeEvery(GET_FILE_TYPES, fetchData)
  yield takeEvery(UPLOAD_FILE, fetchData)
  yield takeEvery(DEL_STD_HOMEWORK, fetchData)
  yield takeEvery(DEL_STD_TEST, fetchData)
  yield takeEvery(DEL_STD_EXAM, fetchData)
  yield takeEvery(GET_UPLOADED_FILE, fetchData)
  yield takeEvery(DELETE_UPLOADED_FILE, fetchData)
  yield takeEvery(GET_STD_EXAM_ACADEMIC_RECORD, fetchData)
  yield takeEvery(GET_STD_TEST_ACADEMIC_RECORD, fetchData)
  yield takeEvery(GET_STD_HW_ACADEMIC_RECORD, fetchData)
  yield takeEvery(GET_PREVIOUS_CLASS_HISTORY, fetchData)
  yield takeEvery(CREATE_PREVIOUS_CLASS_HISTORY, fetchData)
  yield takeEvery(GET_STD_PREV_EXAM_ACADEMIC_RECORD, fetchData)
  yield takeEvery(ADD_STD_SPORTS_REMARKS, fetchData)
  yield takeEvery(GET_DONARS_LIST, fetchData)
  yield takeEvery(GET_NEW_ADMISSION_STUDENT, fetchData)
  yield takeEvery(GENERATE_STUDENT_LIST_REPORT, fetchData)
  yield takeEvery(GENERATE_STUDENT_LOGIN_REPORT, fetchData)
  yield takeEvery(GET_STUDENT_DROPOUT_PDF, fetchData)
  yield takeEvery(GET_MONTH_WISE_STUDENT_DROPOUT_PDF, fetchData)
  yield takeEvery(SMS_LOGIN_DETAILS, fetchData)
  yield takeEvery(EMAIL_LOGIN_DETAILS, fetchData)
  yield takeEvery(GET_ACADEMIC_RECORD, fetchData)
  yield takeEvery(GET_STUDENT_SUBJECT_EXAMS, fetchData)
  yield takeEvery(GET_COUNSELLOR_MENTORS, fetchData)
}

export default dataSaga
