import {takeEvery} from 'redux-saga/effects'
import {
  GET_USER_CUSTOM_ATTENDANCE_SCHEDULE,
  DELETE_USER_CUSTOM_ATTENDANCE_SCHEDULE,
  ADD_USER_CUSTOM_ATTENDANCE_SCHEDULE,
  DELETE_ALL_USER_CUSTOM_ATTENDANCE_SCHEDULE,
  GET_ONE_USER_CUSTOM_ATTENDANCE_SCHEDULE,
  UPDATE_USER_CUSTOM_ATTENDANCE_SCHEDULE,
} from 'setup/redux/Actions/Attendance/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_USER_CUSTOM_ATTENDANCE_SCHEDULE, fetchData)
  yield takeEvery(DELETE_USER_CUSTOM_ATTENDANCE_SCHEDULE, fetchData)
  yield takeEvery(ADD_USER_CUSTOM_ATTENDANCE_SCHEDULE, fetchData)
  yield takeEvery(UPDATE_USER_CUSTOM_ATTENDANCE_SCHEDULE, fetchData)
  yield takeEvery(DELETE_ALL_USER_CUSTOM_ATTENDANCE_SCHEDULE, fetchData)
  yield takeEvery(GET_ONE_USER_CUSTOM_ATTENDANCE_SCHEDULE, fetchData)
}

export default dataSaga
