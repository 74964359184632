import {
  ADD_CERTIFICATE,
  DELETE_CERTIFICATE,
  GENERATE_CERTIFICATE,
  GET_CERTIFICATE,
  GET_CERTIFICATES,
  GET_FILE_TYPES,
  UPDATE_CERTIFICATE,
} from 'setup/redux/Actions/Certificate/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_CERTIFICATES, fetchData)
  yield takeEvery(GET_CERTIFICATE, fetchData)
  yield takeEvery(ADD_CERTIFICATE, fetchData)
  yield takeEvery(UPDATE_CERTIFICATE, fetchData)
  yield takeEvery(DELETE_CERTIFICATE, fetchData)
  yield takeEvery(GENERATE_CERTIFICATE, fetchData)
  yield takeEvery(GET_FILE_TYPES, fetchData)
}

export default dataSaga
