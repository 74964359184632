import {takeEvery} from 'redux-saga/effects'
import {
  GET_ATTENDANCE_REMARKS,
  DELETE_ATTENDANCE_REMARKS,
  GET_BY_TYPE,
  ATTENDANCE_REMARKS_ADD,
} from 'setup/redux/Actions/AttendanceRemarks/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_ATTENDANCE_REMARKS, fetchData)
  yield takeEvery(ATTENDANCE_REMARKS_ADD, fetchData)
  yield takeEvery(GET_BY_TYPE, fetchData)
  yield takeEvery(DELETE_ATTENDANCE_REMARKS, fetchData)
}

export default dataSaga
