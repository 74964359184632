import {
  GET_SCHOOL_LECTURES,
  GET_SCHOOL_LECTURE,
  DELETE_SCHOOL_LECTURE,
  UPDATE_SCHOOL_LECTURE,
  ADD_SCHOOL_LECTURE,
  GET_SCHOOL_LECTURE_ONLINE_USER,
  ADD_SCHOOL_LECTURE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  GET_ALL_COMMENTS,
} from '../../../Actions/School/Lecture/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_SCHOOL_LECTURES, fetchData)
  yield takeEvery(GET_SCHOOL_LECTURE, fetchData)
  yield takeEvery(DELETE_SCHOOL_LECTURE, fetchData)
  yield takeEvery(UPDATE_SCHOOL_LECTURE, fetchData)
  yield takeEvery(ADD_SCHOOL_LECTURE, fetchData)
  yield takeEvery(GET_SCHOOL_LECTURE_ONLINE_USER, fetchData)
  yield takeEvery(ADD_SCHOOL_LECTURE_COMMENT, fetchData)
  yield takeEvery(UPDATE_COMMENT, fetchData)
  yield takeEvery(DELETE_COMMENT, fetchData)
  yield takeEvery(GET_ALL_COMMENTS, fetchData)
}

export default dataSaga
