import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORY,
  GET_CATEGORIES,
  GET_ACTIVE_CATEGORIES,
  UPDATE_CATEGORY,
  FILE_UPLOAD_BOOK,
  ADD_SUBJECT,
  DELETE_SUBJECT,
  GET_BOOK_SUBJECT,
  GET_BOOK_SUBJECTS,
  GET_ACTIVE_SUBJECTS,
  UPDATE_SUBJECT,
  ADD_BOOK,
  DELETE_BOOK,
  GET_BOOK,
  GET_BOOKS,
  GET_ACTIVE_BOOKS,
  UPDATE_BOOK,
  GET_BOOK_RESERVATIONS,
  GET_BOOK_RESERVATIONS_HISTORY,
  GET_BOOK_RESERVATION,
  ADD_BOOK_RESERVATION,
  UPDATE_BOOK_RESERVATION,
  DELETE_BOOK_RESERVATION,
  SEND_MAIL_RESERVATION,
  GET_USER_RESERVATIONS,
  GET_BOOK_SETTINGS,
  GET_BOOK_SETTING,
  UPDATE_BOOK_SETTING,
  GET_BOOK_RENEWAL_REQUEST,
  UPDATE_BOOK_RENEWAL_REQUEST,
  BOOK_RETURN,
  DELETE_BOOKS,
  LIBRARY_STATS,
} from 'setup/redux/Actions/Library/ActionTypes/ApiActionTypes'

import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  // Category
  yield takeEvery(GET_CATEGORIES, fetchData)
  yield takeEvery(GET_ACTIVE_CATEGORIES, fetchData)
  yield takeEvery(GET_CATEGORY, fetchData)
  yield takeEvery(ADD_CATEGORY, fetchData)
  yield takeEvery(UPDATE_CATEGORY, fetchData)
  yield takeEvery(DELETE_CATEGORY, fetchData)

  // Subject
  yield takeEvery(GET_BOOK_SUBJECTS, fetchData)
  yield takeEvery(GET_ACTIVE_SUBJECTS, fetchData)
  yield takeEvery(GET_BOOK_SUBJECT, fetchData)
  yield takeEvery(ADD_SUBJECT, fetchData)
  yield takeEvery(UPDATE_SUBJECT, fetchData)
  yield takeEvery(DELETE_SUBJECT, fetchData)

  // Book
  yield takeEvery(GET_BOOKS, fetchData)
  yield takeEvery(GET_ACTIVE_BOOKS, fetchData)
  yield takeEvery(GET_BOOK, fetchData)
  yield takeEvery(ADD_BOOK, fetchData)
  yield takeEvery(UPDATE_BOOK, fetchData)
  yield takeEvery(DELETE_BOOK, fetchData)
  yield takeEvery(DELETE_BOOKS, fetchData)

  yield takeEvery(FILE_UPLOAD_BOOK, fetchData)

  // Book Reservation
  yield takeEvery(GET_BOOK_RESERVATIONS, fetchData)
  yield takeEvery(GET_BOOK_RESERVATIONS_HISTORY, fetchData)

  yield takeEvery(GET_BOOK_RESERVATION, fetchData)
  yield takeEvery(ADD_BOOK_RESERVATION, fetchData)
  yield takeEvery(UPDATE_BOOK_RESERVATION, fetchData)
  yield takeEvery(DELETE_BOOK_RESERVATION, fetchData)

  yield takeEvery(BOOK_RETURN, fetchData)

  yield takeEvery(SEND_MAIL_RESERVATION, fetchData)

  yield takeEvery(GET_USER_RESERVATIONS, fetchData)

  // Setting
  yield takeEvery(GET_BOOK_SETTINGS, fetchData)
  yield takeEvery(GET_BOOK_SETTING, fetchData)
  yield takeEvery(UPDATE_BOOK_SETTING, fetchData)

  // Renewal Request
  yield takeEvery(GET_BOOK_RENEWAL_REQUEST, fetchData)
  yield takeEvery(UPDATE_BOOK_RENEWAL_REQUEST, fetchData)

  yield takeEvery(LIBRARY_STATS, fetchData)
}

export default dataSaga
