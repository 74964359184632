import {takeEvery} from 'redux-saga/effects'

import fetchData from '../../SagaHelper'
import {GET_EXAM_TYPES} from 'setup/redux/Actions/ExamType/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_EXAM_TYPES, fetchData)
}

export default dataSaga
