import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_COUNT,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATIONS,
} from '../../Actions/Notifications/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchData)
  yield takeEvery(GET_NOTIFICATIONS_COUNT, fetchData)
  yield takeEvery(READ_NOTIFICATIONS, fetchData)
  yield takeEvery(READ_ALL_NOTIFICATIONS, fetchData)
}

export default dataSaga
