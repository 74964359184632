/* eslint-disable */
let mixed = {
  default: '${path} geçersiz',
  required: '${path} gerekli',
  oneOf: '${path} aşağıdaki değerlerden biri olmalıdır: ${values}',
  notOneOf: '${path} aşağıdaki değerlerden biri olmamalıdır: ${values}',
  notType: ({path, type, value, originalValue}) => {
    // let isCast = originalValue != null && originalValue !== value
    let msg = `${path} \`${type}\` türünde olmalıdır`
    //   + `ama son değer: \`${(0, _printValue.default)(value, true)}\` oldu` +
    //   (isCast ? ` (değer \`${(0, _printValue.default)(originalValue, true)}\`'den çevrildi).` : '.')

    // if (value === null) {
    //   msg += `\n Eğer "null" boş bir değer olarak kabul ediliyorsa, şemanın \`.nullable()\` olarak işaretlendiğinden emin olun`
    // }

    return msg
  },
  defined: '${path} tanımlanmış olmalıdır',
}

let string = {
  length: '${path} tam olarak ${length} karakter olmalıdır',
  min: '${path} en az ${min} karakter olmalıdır',
  max: '${path} en fazla ${max} karakter olmalıdır',
  matches: '${path} şu regex ile eşleşmelidir: "${regex}"',
  email: '${path} geçerli bir e-posta adresi olmalıdır',
  url: '${path} geçerli bir URL olmalıdır',
  uuid: '${path} geçerli bir UUID olmalıdır',
  trim: '${path} kırpılmış bir metin olmalıdır',
  lowercase: '${path} küçük harfli bir metin olmalıdır',
  uppercase: '${path} büyük harfli bir metin olmalıdır',
}

let number = {
  min: '${path} ${min} değerine eşit veya büyük olmalıdır',
  max: '${path} ${max} değerine eşit veya küçük olmalıdır',
  lessThan: '${path} ${less} değerinden küçük olmalıdır',
  moreThan: '${path} ${more} değerinden büyük olmalıdır',
  positive: '${path} pozitif bir sayı olmalıdır',
  negative: '${path} negatif bir sayı olmalıdır',
  integer: '${path} bir tamsayı olmalıdır',
}

let date = {
  min: '${path} alanı ${min} tarihinden sonra olmalıdır',
  max: '${path} alanı ${max} tarihinden önce olmalıdır',
}

let boolean = {
  isValue: '${path} alanı ${value} olmalıdır',
}

let object = {
  noUnknown: '${path} alanı bilinmeyen anahtarlar içeriyor: ${unknown}',
}

let array = {
  min: '${path} alanı en az ${min} öğe içermelidir',
  max: '${path} alanı en fazla ${max} öğe içermelidir',
  length: '${path} ${length} öğe içermelidir',
}

const tr = Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
})

export default tr
