import {
  ADD_VENDOR,
  CONFIRM_VENDOR_FILE_UPLOAD,
  DELETE_VENDOR,
  DELETE_VENDOR_OLD_CNIC_FILES,
  GET_VENDOR,
  GET_VENDORS,
  GET_VENDOR_SETTINGS,
  GET_VENDOR_SIGNED_UPLOAD_URL,
  REGISTER_VENDOR,
  UPDATE_VENDOR,
  UPDATE_VENDOR_SETTINGS,
} from './ActionTypes/ApiActionTypes'
import constants from 'common/constants'

export const getVendors = (data, onSuccess, onFailure) => {
  return {
    type: GET_VENDORS,
    payload: {
      apiUrl: constants.api_get_vendors,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: data,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getVendor = (id, onSuccess, onFailure) => {
  return {
    type: GET_VENDOR,
    payload: {
      apiUrl: constants.api_get_vendor(id),
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const addVendor = (data, onSuccess, onFailure) => {
  return {
    type: ADD_VENDOR,
    payload: {
      apiUrl: constants.api_add_vendor,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: data,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const updateVendor = (id, data, onSuccess, onFailure) => {
  return {
    type: UPDATE_VENDOR,
    payload: {
      apiUrl: constants.api_update_vendors(id),
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: data,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const deleteVendor = (id, onSuccess, onFailure) => {
  return {
    type: DELETE_VENDOR,
    payload: {
      apiUrl: constants.api_delete_vendor(id),
      getString: '',
      useBaseURL: true,
      requestType: 'DELETE',
      header: 'application/json',
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const registerVendor = (id, data, onSuccess, onFailure) => {
  return {
    type: REGISTER_VENDOR,
    payload: {
      apiUrl: constants.api_register_vendor(id),
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: data,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getSignedUrl = (body, onSuccess, onFailure) => {
  return {
    type: GET_VENDOR_SIGNED_UPLOAD_URL,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: constants.api_get_vendor_signed_upload_url,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      reduxActionType: '',
      body: body,
    },
  }
}

export const deleteOldCnicFiles = (vendorId, onSuccess, onFailure) => {
  return {
    type: DELETE_VENDOR_OLD_CNIC_FILES,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: constants.api_delete_vendor_old_cnic_files(vendorId),
      getString: '',
      useBaseURL: true,
      requestType: 'DELETE',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const confirmFileUpload = (body, onSuccess, onFailure) => {
  return {
    type: CONFIRM_VENDOR_FILE_UPLOAD,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: constants.api_confirm_vendor_file_upload,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      reduxActionType: '',
      body: body,
    },
  }
}

export const getVendorSettings = (onSuccess, onFailure) => {
  return {
    type: GET_VENDOR_SETTINGS,
    payload: {
      apiUrl: constants.api_get_vendor_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const updateVendorSettings = (data, onSuccess, onFailure) => {
  return {
    type: UPDATE_VENDOR_SETTINGS,
    payload: {
      apiUrl: constants.api_update_vendor_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: data,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}
