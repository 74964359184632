import {
  DELETE_YEARLY_SCHEDULE,
  DELETE_DAILY_SCHEDULE,
  GET_SCHEDULE_DAILY_CALENDAR,
  GET_SCHEDULE_YEARLY_CALENDAR,
  GET_SCHEDULE_YEARLY_LIST,
  GET_SCHEDULE_YEARLY_BY_ID,
  UPDATE_YEARLY_SCHEDULE,
  GET_SCHEDULE_DAILY_LIST,
  GET_SCHEDULE_DAILY_BY_ID,
  UPDATE_DAILY_SCHEDULE,
  ADD_YEARLY_SCHEDULE,
  ADD_DAILY_SCHEDULE,
  GET_SCHEDULE_CLASS_LIST,
  DELETE_DAILY_SCHEDULE_CLASSES,
  ADD_CLASSROOM,
  UPDATE_CLASSROOM,
  GET_CLASSROOM_BY_ID,
  ADD_EXTRACLASS,
  DELETE_MANY_DAILY_SCHEDULE
} from '../../../Actions/School/Schedule/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_SCHEDULE_YEARLY_LIST, fetchData)
  yield takeEvery(GET_SCHEDULE_DAILY_CALENDAR, fetchData)
  yield takeEvery(GET_SCHEDULE_YEARLY_CALENDAR, fetchData)
  yield takeEvery(DELETE_YEARLY_SCHEDULE, fetchData)
  yield takeEvery(GET_SCHEDULE_YEARLY_BY_ID, fetchData)
  yield takeEvery(UPDATE_YEARLY_SCHEDULE, fetchData)
  yield takeEvery(UPDATE_DAILY_SCHEDULE, fetchData)
  yield takeEvery(GET_SCHEDULE_DAILY_LIST, fetchData)
  yield takeEvery(DELETE_DAILY_SCHEDULE, fetchData)
  yield takeEvery(DELETE_MANY_DAILY_SCHEDULE, fetchData)
  yield takeEvery(GET_SCHEDULE_DAILY_BY_ID, fetchData)
  yield takeEvery(ADD_YEARLY_SCHEDULE, fetchData)
  yield takeEvery(GET_SCHEDULE_CLASS_LIST, fetchData)
  yield takeEvery(DELETE_DAILY_SCHEDULE_CLASSES, fetchData)
  yield takeEvery(ADD_CLASSROOM, fetchData)
  yield takeEvery(UPDATE_CLASSROOM, fetchData)
  yield takeEvery(GET_CLASSROOM_BY_ID, fetchData)
  yield takeEvery(ADD_DAILY_SCHEDULE, fetchData)
  yield takeEvery(ADD_EXTRACLASS, fetchData)
}

export default dataSaga
