import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/ar'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/tr'
import '@formatjs/intl-relativetimeformat/locale-data/ur'

import arMessages from './messages/ar.json'
import enMessages from './messages/en.json'
import frMessages from './messages/fr.json'
import trMessages from './messages/tr.json'
import urMessages from './messages/ur.json'

const allMessages = {
  ar: arMessages,
  en: enMessages,
  fr: frMessages,
  tr: trMessages,
  ur: urMessages,
}

const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
