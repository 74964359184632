import {useEffect, useState} from 'react'
import {Link, Navigate, Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '_metronic/helpers'
import {getGeneralSettings} from 'setup/redux/Actions/General/settings'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState, UserReducer} from 'setup'
import {Login} from './components/Login'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {ResetPassword} from './components/ResetPassword'
import {Auth} from './components/Auth'
import Logo from 'common/components/Logo'
import {getTenantCustomImage, getTenantName} from 'common/HelperFunctions'

const AuthLayout = () => {
  const dispatch = useDispatch()

  const generalSettings = useSelector<any>(({mainReducer}) => mainReducer.generalSettings) as any

  const [backgroundUrl, setBackgroundUrl] = useState(toAbsoluteUrl('/media/background/3.jpg'))

  const initBackgroundImage = () => {
    const customBackgroundImage = getTenantCustomImage('background_image')
    if (customBackgroundImage) setBackgroundUrl(customBackgroundImage)
    else if (generalSettings.background_image) setBackgroundUrl(generalSettings.background_image)
  }

  useEffect(() => {
    dispatch(getGeneralSettings())
    initBackgroundImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    initBackgroundImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSettings])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <Link to='/auth/login' className='mb-12'>
          <Logo
            showBranchLogo={false}
            style={{
              maxHeight: '200px',
            }}
          />
        </Link>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          {/* {!generalSettings?.powered_by_text && (
            <span>
              Powered by
              <a
                href='https://ilmversity.com'
                target='_blank'
                className='text-dark text-hover-primary px-2'
                rel='noreferrer'
              >
                Ilmversity
              </a>
            </span>
          )} */}
          {getTenantName() !== 'lgs' && !!generalSettings?.powered_by_text && (
            <div dangerouslySetInnerHTML={{__html: generalSettings?.powered_by_text}} />
          )}
          {/* <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a> */}
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => {
  const navigate = useNavigate()
  const {isLoggedIn} = useSelector<RootState>(
    (state) => state.userReducer,
    shallowEqual
  ) as UserReducer

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route index element={<Auth />} />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
