import {applyMiddleware, createStore, compose} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import localforage from 'localforage'

import app from './Reducers/index'
import rootSaga from './Sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: process.env.REACT_APP_REDUX_STATE_KEY,
  storage: localforage,
}

const persistedReducer = persistReducer(persistConfig, app)

export default function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    persistedReducer,
    undefined,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )
  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)

  return {store, persistor}
}
