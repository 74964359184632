import {
  GET_TEACHER_LIST,
  IN_ACTIVATE_USER,
  GET_TEACHER_BY_ID,
  GET_TEACHER_CLASSES_BY_ID,
  GET_TEACHER_SUBJECTS_BY_ID,
  GET_TEACHER_QUALIFICATIONS_BY_ID,
  GET_TEACHER_EXPERIENCE_BY_ID,
  GET_TEACHER_CHILDREN_BY_ID,
  DELETE_TEACHER_GENERAL_REMARKS,
  GET_TEACHER_GENERAL_REMARKS_BY_ID,
  ADD_TEACHER_GENERAL_REMARKS,
  UPDATE_TEACHER_GENERAL_REMARKS_BY_ID,
  GET_TEACHER_UPLOADED_FILES,
  GET_TEACHER_FILE_TYPES,
  ADD_TEACHER_UPLOAD_FILE,
  UPDATE_TEACHER,
  ADD_TEACHER,
  SMS_TEACHER_LOGIN_DETAILS,
  GENERATE_TEACHER_LOGIN_REPORT,
  EMAIL_TEACHER_LOGIN_DETAILS,
  GENERATE_TEACHER_LIST_REPORT_PDF,
  UPLOAD_SIGNATURE_IMAGE,
} from '../../../Actions/School/Teacher/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_TEACHER_LIST, fetchData)
  yield takeEvery(IN_ACTIVATE_USER, fetchData)
  yield takeEvery(GET_TEACHER_BY_ID, fetchData)
  yield takeEvery(GET_TEACHER_CLASSES_BY_ID, fetchData)
  yield takeEvery(GET_TEACHER_SUBJECTS_BY_ID, fetchData)
  yield takeEvery(GET_TEACHER_QUALIFICATIONS_BY_ID, fetchData)
  yield takeEvery(GET_TEACHER_EXPERIENCE_BY_ID, fetchData)
  yield takeEvery(GET_TEACHER_CHILDREN_BY_ID, fetchData)
  yield takeEvery(DELETE_TEACHER_GENERAL_REMARKS, fetchData)
  yield takeEvery(GET_TEACHER_GENERAL_REMARKS_BY_ID, fetchData)
  yield takeEvery(ADD_TEACHER_GENERAL_REMARKS, fetchData)
  yield takeEvery(UPDATE_TEACHER_GENERAL_REMARKS_BY_ID, fetchData)
  yield takeEvery(GET_TEACHER_UPLOADED_FILES, fetchData)
  yield takeEvery(GET_TEACHER_FILE_TYPES, fetchData)
  yield takeEvery(ADD_TEACHER_UPLOAD_FILE, fetchData)
  yield takeEvery(UPDATE_TEACHER, fetchData)
  yield takeEvery(ADD_TEACHER, fetchData)
  yield takeEvery(EMAIL_TEACHER_LOGIN_DETAILS, fetchData)
  yield takeEvery(GENERATE_TEACHER_LOGIN_REPORT, fetchData)
  yield takeEvery(SMS_TEACHER_LOGIN_DETAILS, fetchData)
  yield takeEvery(GENERATE_TEACHER_LIST_REPORT_PDF, fetchData)
  yield takeEvery(UPLOAD_SIGNATURE_IMAGE, fetchData)
}

export default dataSaga
