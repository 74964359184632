export const GET_PARENT_LIST = 'GET_PARENT_LIST'
export const GET_PARENT_BY_ID = 'GET_PARENT_BY_ID'
export const GET_PARENT_CHILDREN_LIST = 'GET_PARENT_CHILDREN_LIST'
export const GET_EMERGENCY_LIST_ID = 'GET_EMERGENCY_LIST_ID'
export const ADD_PARENT = 'ADD_PARENT'
export const UPDATE_PARENT = 'UPDATE_PARENT'
export const ADD_EMERGENCY_CONTACTS = 'ADD_EMERGENCY_CONTACTS'
export const GENERATE_PARENT_LOGIN_REPORT = 'GENERATE_PARENT_LOGIN_REPORT'
export const SMS_PARENT_LOGIN_DETAILS = 'SMS_PARENT_LOGIN_DETAILS'
export const EMAIL_PARENT_LOGIN_DETAILS = 'EMAIL_PARENT_LOGIN_DETAILS'
export const GENERATE_PARENT_SIBLING_REPORT_PDF = 'GENERATE_PARENT_SIBLING_REPORT_PDF'
