import {
  GET_GRADES,
  GET_GRADE,
  DELETE_GRADE,
  ADD_GRADE,
  DELETE_MANY_GRADES,
  UPDATE_GRADE,
  REDUX_GET_GRADES,
  GET_SUBJECT_GRADES,
} from '../../../Actions/School/Grade/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_GRADES, fetchData)
  yield takeEvery(GET_GRADE, fetchData)
  yield takeEvery(DELETE_GRADE, fetchData)
  yield takeEvery(ADD_GRADE, fetchData)
  yield takeEvery(DELETE_MANY_GRADES, fetchData)
  yield takeEvery(UPDATE_GRADE, fetchData)
  yield takeEvery(REDUX_GET_GRADES, fetchData)
  yield takeEvery(GET_SUBJECT_GRADES, fetchData)
}

export default dataSaga
