import {
  ADD_VENDOR,
  CONFIRM_VENDOR_FILE_UPLOAD,
  DELETE_VENDOR,
  DELETE_VENDOR_OLD_CNIC_FILES,
  GET_VENDOR,
  GET_VENDORS,
  GET_VENDOR_SETTINGS,
  GET_VENDOR_SIGNED_UPLOAD_URL,
  REGISTER_VENDOR,
  UPDATE_VENDOR,
  UPDATE_VENDOR_SETTINGS,
} from 'setup/redux/Actions/Vendor/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_VENDORS, fetchData)
  yield takeEvery(GET_VENDOR, fetchData)
  yield takeEvery(ADD_VENDOR, fetchData)
  yield takeEvery(UPDATE_VENDOR, fetchData)
  yield takeEvery(DELETE_VENDOR, fetchData)
  yield takeEvery(REGISTER_VENDOR, fetchData)
  yield takeEvery(GET_VENDOR_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(DELETE_VENDOR_OLD_CNIC_FILES, fetchData)
  yield takeEvery(CONFIRM_VENDOR_FILE_UPLOAD, fetchData)

  yield takeEvery(GET_VENDOR_SETTINGS, fetchData)
  yield takeEvery(UPDATE_VENDOR_SETTINGS, fetchData)
}

export default dataSaga
