import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'
import {
  GET_TAGS_CATEGORIES,
  GET_TAGS_CATEGORY,
  ADD_TAGS_CATEGORY,
  UPDATE_TAGS_CATEGORY,
  DELETE_TAGS_CATEGORY,
} from 'setup/redux/Actions/School/TagsCategory/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_TAGS_CATEGORIES, fetchData)
  yield takeEvery(GET_TAGS_CATEGORY, fetchData)
  yield takeEvery(ADD_TAGS_CATEGORY, fetchData)
  yield takeEvery(UPDATE_TAGS_CATEGORY, fetchData)
  yield takeEvery(DELETE_TAGS_CATEGORY, fetchData)
}

export default dataSaga
