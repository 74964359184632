import {takeEvery} from 'redux-saga/effects'
import {
  GET_INVOICE,
  GET_INVOICES,
  ADD_INVOICE,
  UPDATE_INVOICE,
  DELETE_INVOICE_BY_ID,
  DELETE_INVOICE_IN_BULK,
  INVOICE_REPORT,
} from 'setup/redux/Actions/Invoice/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_INVOICES, fetchData)
  yield takeEvery(GET_INVOICE, fetchData)
  yield takeEvery(ADD_INVOICE, fetchData)
  yield takeEvery(UPDATE_INVOICE, fetchData)
  yield takeEvery(DELETE_INVOICE_IN_BULK, fetchData)
  yield takeEvery(DELETE_INVOICE_BY_ID, fetchData)
  yield takeEvery(INVOICE_REPORT, fetchData)
}

export default dataSaga
