import {takeEvery} from 'redux-saga/effects'
import {
  GET_DOCUMENT,
  GET_DOCUMENTS,
  DELETE_DOCUMENT,
} from 'setup/redux/Actions/Document/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_DOCUMENTS, fetchData)
  yield takeEvery(GET_DOCUMENT, fetchData)
  yield takeEvery(DELETE_DOCUMENT, fetchData)
}

export default dataSaga
