export const GET_BRANCHES = 'GET_BRANCHES'
export const GET_BRANCH = 'GET_BRANCH'
export const DELETE_BRANCH = 'DELETE_BRANCH'
export const ADD_BRANCH = 'ADD_BRANCH'
export const VIEW_BRANCH = 'VIEW_BRANCH'
export const UPDATE_BRANCH = 'UPDATE_BRANCH'
export const GET_BRANCH_LOGO_SIGNED_UPLOAD_URL = 'GET_BRANCH_LOGO_SIGNED_UPLOAD_URL'
export const CONFIRM_BRANCH_LOGO_FILE_UPLOAD = 'CONFIRM_BRANCH_LOGO_FILE_UPLOAD'
export const GET_BRANCH_BANNER_SIGNED_UPLOAD_URL = 'GET_BRANCH_BANNER_SIGNED_UPLOAD_URL'
export const CONFIRM_BRANCH_BANNER_FILE_UPLOAD = 'CONFIRM_BRANCH_BANNER_FILE_UPLOAD'
export const DELETE_BRANCH_BANNER_FILE = 'DELETE_BRANCH_BANNER_FILE'
export const DELETE_BRANCH_LOGO_FILE = 'DELETE_BRANCH_LOGO_FILE'
export const CHECK_BRANCH_LIMIT = 'CHECK_BRANCH_LIMIT'
