import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'
import {
  ADD_RESOURCE,
  CONFIRM_RESOURCE_FILE_UPLOAD,
  DELETE_RESOURCE,
  GET_RESOURCE,
  GET_RESOURCES,
  GET_RESOURCE_SIGNED_UPLOAD_URL,
  RESOURCE_DELETE_FILE,
  UPDATE_RESOURCE,
} from 'setup/redux/Actions/Resource/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_RESOURCES, fetchData)
  yield takeEvery(GET_RESOURCE, fetchData)
  yield takeEvery(ADD_RESOURCE, fetchData)
  yield takeEvery(UPDATE_RESOURCE, fetchData)
  yield takeEvery(DELETE_RESOURCE, fetchData)

  yield takeEvery(GET_RESOURCE_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(CONFIRM_RESOURCE_FILE_UPLOAD, fetchData)
  yield takeEvery(RESOURCE_DELETE_FILE, fetchData)
}

export default dataSaga
