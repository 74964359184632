import {Outlet} from 'react-router-dom'

const FullLayout = () => {
  return (
    <div className='d-flex flex-column min-vh-100'>
      <div className='flex-grow-1 container my-5'>
        <Outlet />
      </div>
      {/* begin::Footer */}
      <div className='bg-secondary d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          Powered by
          <a
            href='https://ilmversity.com'
            target='_blank'
            className='text-dark text-hover-primary px-2'
            rel='noreferrer'
          >
            Ilmversity
          </a>
          {/* <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a> */}
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export default FullLayout
