import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'
import {
  CREATE_STAFF_ROLE,
  DELETE_STAFF_ROLE,
  GET_STAFF_ROLE,
  GET_STAFF_ROLES,
} from 'setup/redux/Actions/StaffRole/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_STAFF_ROLES, fetchData)
  yield takeEvery(GET_STAFF_ROLE, fetchData)
  yield takeEvery(CREATE_STAFF_ROLE, fetchData)
  yield takeEvery(DELETE_STAFF_ROLE, fetchData)
}

export default dataSaga
