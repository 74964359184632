import {takeEvery} from 'redux-saga/effects'
import {
  ADD_ADMISSION_FEE_VOUCHER_DATES,
  DELETE_ADMISSION_FEE_VOUCHER_DATES,
  DELETE_MANY_ADMISSION_FEE_VOUCHER_DATES,
  GET_ADMISSION_FEE_VOUCHER_DATES,
  UPDATE_ADMISSION_FEE_VOUCHER_DATES,
} from 'setup/redux/Actions/School/AdmissionFeeVoucherDate/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_ADMISSION_FEE_VOUCHER_DATES, fetchData)
  yield takeEvery(ADD_ADMISSION_FEE_VOUCHER_DATES, fetchData)
  yield takeEvery(UPDATE_ADMISSION_FEE_VOUCHER_DATES, fetchData)
  yield takeEvery(DELETE_ADMISSION_FEE_VOUCHER_DATES, fetchData)
  yield takeEvery(DELETE_MANY_ADMISSION_FEE_VOUCHER_DATES, fetchData)
}

export default dataSaga
