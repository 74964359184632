import {getTenantCustomDomain} from 'common/HelperFunctions'
import Spinner from 'common/components/Spinner'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {REDUX_DESTROY_SESSION} from 'setup/redux/Actions/SignIn/ActionTypes/ReduxActionTypes'
import {logout} from 'setup/redux/Actions/SignIn/logout'

export function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [iframeLoading, setIframeLoading] = useState(true)

  useEffect(() => {})

  useEffect(() => {
    if (!iframeLoading) {
      dispatch(
        logout(
          () => {
            navigate('/auth/login')
          },
          () => {
            dispatch({
              type: REDUX_DESTROY_SESSION,
            })
          }
        )
      )
    }
  }, [dispatch, navigate, iframeLoading])

  // if (!iframeLoading) return <Navigate to='/auth/login' />
  return (
    <div className='d-flex flex-column align-items-center justify-content-center vh-100 bg-body'>
      <Spinner type='grow' size='lg' color='primary' />

      <iframe
        id='iframe'
        title='frame'
        src={`//${getTenantCustomDomain()}/site/logout`}
        onLoad={() => setIframeLoading(false)}
        className='position-absolute top-0 start-0 h-100 w-100 z-index-n2'
      />
    </div>
  )
}
