import {RouteObject} from 'react-router-dom'

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
  PARENT: 'PARENT',
  DEFAULT: 'DEFAULT',
  STAFF: 'STAFF',
}

export const USER_TYPES_NUMERIC = {
  ADMIN: 0,
  STUDENT: 1,
  TEACHER: 2,
  PARENT: 3,
  DEFAULT: 4,
  STAFF: 5,
}

export const USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  JUNK: 2,
}

export const USER_INACTIVE_REASON = {
  INACTIVE: 0,
  NEW_ADMISSION: 1,
  ALUMNI: 2,
}

export const PARENT_TYPES = {
  FATHER: 1,
  MOTHER: 2,
  GUARDIAN: 3,
}

export const INVOICE_TYPES = {
  BOTH: 'both',
  ISSUED: 'issued',
  SOLD: 'sold',
}

export const USER_BETA_TOGGLE_TENANTS = [
  // 'creatics',
  'demo',
  'democollege',
  // 'lgs',
  // 'maryammont',
  'myschool',
  // 'ivss',
  // 'pgss',
  // 'risc',
  // 'zicas',
  'lgsdev2',
  // 'lgs',
]

export const USER_MODULE_BETA_KEY = 'user-beta-toggle'

export const USER_SHOW_ROUTES: RouteObject[] = [
  {path: '/institute/inactive-users/*'},
  {path: '/institute/parent/*'},
  {path: '/institute/staffRole/*'},
  {path: '/institute/staff/*'},
  {path: '/institute/student/*'},
  {path: '/institute/teacher/*'},
  {path: '/institute/user/*'},
  {path: '/institute/userLabel/*'},
]
