export const GET_STORE_ITEM_LIST = 'GET_STORE_ITEM_LIST'
export const DELETE_STORE_ITEM_BY_ID = 'DELETE_STORE_ITEM_BY_ID'
export const GET_STORE_ITEM_BY_ID = 'GET_STORE_ITEM_BY_ID'
export const DELETE_STORE_ITEMS_IN_BULK = 'DELETE_STORE_ITEMS_IN_BULK'
export const ADD_STORE_ITEM = 'ADD_STORE_ITEM'
export const UPDATE_STORE_ITEM = 'UPDATE_STORE_ITEM'
export const GET_STORE_ITEM_TAGS = 'GET_STORE_ITEM_TAGS'
export const GET_STORE_ITEM_PERMITTED_TAGS = 'GET_STORE_ITEM_PERMITTED_TAGS'
export const GET_STOCK_REPORT = 'GET_STOCK_REPORT'
export const GET_PURCHASE_ORDER_REPORT = 'GET_PURCHASE_ORDER_REPORT'
export const GET_ISSUE_SOLD_STOCK_REPORT = 'GET_ISSUE_SOLD_STOCK_REPORT'
export const STORE_ITEM_HISTORY = 'STORE_ITEM_HISTORY'
