import {
  LOGIN_USER,
  LOGIN_USER_JWT,
  LOGOUT_USER,
  USER_ME,
} from '../../Actions/SignIn/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(LOGIN_USER, fetchData)
  yield takeEvery(LOGIN_USER_JWT, fetchData)
  yield takeEvery(LOGOUT_USER, fetchData)
  yield takeEvery(USER_ME, fetchData)
}

export default dataSaga
