export const LIBRARY_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
}

export const LIBRARY_BOOK_RESERVATION_STATUS = {
  LATE: 2,
  RESERVED: 1,
  RETURNED: 3,
  PENDING_RENEWAL: 4,
  REJECTED_RENEWAL: 5,
}
