import {RouteObject} from 'react-router-dom'

export const ATTENDANCE_MODULE_BETA_KEY = 'attendance-toggle'

export const ATTENDANCE_SHOW_ROUTES: RouteObject[] = [
  {path: '/institute/attendance/*'},
  {path: '/institute/attendanceReport/*'},
  {path: '/institute/userCustomAtendanceSchedule/admin'},
]

export const ATTENDANCE_BETA_TOGGLE_TENANTS = [
  'demo',
  'democollege',
  'myschool',
  'mgs',
  'lihs',
  'wlgs',
  'concordia',
  'rayden',
  'nis',
  'pss',
]
