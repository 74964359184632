// them config props
type Props = {
  selectedTheme: 'light' | 'dark'
}

// initial theme config
const initialConfig: Props = {
  selectedTheme: 'light',
}

// get selected theme config from local storage
// this is a utility method it should be moved to separate file
export const getTheme = (): Props => {
  const THEME_CONFIG_KEY = process.env.REACT_APP_THEME_CONFIG_KEY || 'themeConfig'
  const themeConfig = localStorage.getItem(THEME_CONFIG_KEY)
  if (themeConfig) {
    try {
      return JSON.parse(themeConfig) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialConfig
}

// Change/select theme
// this is a utility method it should be moved to separate file
export const setTheme = (theme: string) => {
  const THEME_CONFIG_KEY = process.env.REACT_APP_THEME_CONFIG_KEY || 'themeConfig'

  localStorage.setItem(THEME_CONFIG_KEY, JSON.stringify({selectedTheme: theme}))
  window.location.reload()
}

// get selected theme
// this is a utility method it should be moved to separate file
export const useTheme = () => {
  return getTheme().selectedTheme
}
