export const GET_USER_SUBJECTS = 'GET_USER_SUBJECTS'

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const GET_DEPARTMENT = 'GET_DEPARTMENT'
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT'
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'

export const GET_BRANCH = 'GET_BRANCH'

export const GET_GENERAL_SETTINGS = 'GET_GENERAL_SETTINGS'
export const UPDATE_GENERAL_SETTINGS = 'UPDATE_GENERAL_SETTINGS'

export const GET_ID_CARD_SETTINGS = 'GET_ID_CARD_SETTINGS'
export const GET_STAFF_ID_CARD_SETTINGS = 'GET_STAFF_ID_CARD_SETTINGS'
export const GET_OUTLINE_SETTINGS = 'GET_OUTLINE_SETTINGS'
export const GET_COMPREHANSIVE_SETTINGS = 'GET_COMPREHANSIVE_SETTINGS'
export const GET_PREDICTED_SETTINGS = 'GET_PREDICTED_SETTINGS'
export const GET_EAR_SERVICES = 'GET_EAR_SERVICES'
export const GET_ADMISSION_SETTINGS = 'GET_ADMISSION_SETTINGS'
export const UPDATE_ENABLE_ADMISSION_FORM_SETTING = 'UPDATE_ENABLE_ADMISSION_FORM_SETTING'
