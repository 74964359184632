import {
  GET_STORE_ITEM_LIST,
  GET_STORE_ITEM_TAGS,
  DELETE_STORE_ITEM_BY_ID,
  GET_STORE_ITEM_BY_ID,
  DELETE_STORE_ITEMS_IN_BULK,
  UPDATE_STORE_ITEM,
  ADD_STORE_ITEM,
  GET_STORE_ITEM_PERMITTED_TAGS,
  GET_STOCK_REPORT,
  GET_PURCHASE_ORDER_REPORT,
  GET_ISSUE_SOLD_STOCK_REPORT,
  STORE_ITEM_HISTORY,
} from '../../../Actions/StoreItems/ActionTypes/ApiActionTypes'
import {
  GET_STORE_ITEM_PACKAGE_LIST,
  DELETE_STORE_ITEM_PACKAGE_BY_ID,
  GET_STORE_ITEM_PACKAGE_BY_ID,
  DELETE_STORE_PACKAGE_ITEMS_IN_BULK,
  UPDATE_STORE_ITEM_PACKAGE,
  ADD_STORE_ITEM_PACKAGE,
} from '../../../Actions/StoreItems/Packages/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_STORE_ITEM_LIST, fetchData)
  yield takeEvery(GET_STORE_ITEM_TAGS, fetchData)
  yield takeEvery(DELETE_STORE_ITEM_BY_ID, fetchData)
  yield takeEvery(GET_STORE_ITEM_BY_ID, fetchData)
  yield takeEvery(DELETE_STORE_ITEMS_IN_BULK, fetchData)
  yield takeEvery(UPDATE_STORE_ITEM, fetchData)
  yield takeEvery(ADD_STORE_ITEM, fetchData)
  yield takeEvery(GET_STORE_ITEM_PACKAGE_LIST, fetchData)
  yield takeEvery(DELETE_STORE_ITEM_PACKAGE_BY_ID, fetchData)
  yield takeEvery(GET_STORE_ITEM_PACKAGE_BY_ID, fetchData)
  yield takeEvery(DELETE_STORE_PACKAGE_ITEMS_IN_BULK, fetchData)
  yield takeEvery(UPDATE_STORE_ITEM_PACKAGE, fetchData)
  yield takeEvery(ADD_STORE_ITEM_PACKAGE, fetchData)
  yield takeEvery(GET_STORE_ITEM_PERMITTED_TAGS, fetchData)
  yield takeEvery(GET_STOCK_REPORT, fetchData)
  yield takeEvery(GET_PURCHASE_ORDER_REPORT, fetchData)
  yield takeEvery(GET_ISSUE_SOLD_STOCK_REPORT, fetchData)
  yield takeEvery(STORE_ITEM_HISTORY, fetchData)
}

export default dataSaga
