import {
  ADD_REQUEST,
  CONFIRM_REQUEST_FILE_UPLOAD,
  DELETE_REQUEST,
  GET_REQUEST,
  GET_REQUESTS,
  GET_REQUEST_SETTINGS,
  GET_REQUEST_SIGNED_UPLOAD_URL,
  UPDATE_REQUEST,
  UPDATE_REQUEST_SETTINGS,
} from 'setup/redux/Actions/Request/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_REQUESTS, fetchData)
  yield takeEvery(GET_REQUEST, fetchData)
  yield takeEvery(ADD_REQUEST, fetchData)
  yield takeEvery(UPDATE_REQUEST, fetchData)
  yield takeEvery(DELETE_REQUEST, fetchData)

  yield takeEvery(GET_REQUEST_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(CONFIRM_REQUEST_FILE_UPLOAD, fetchData)
  // yield takeEvery(DELETE_REQUEST_ATTACHMENT, fetchData)

  yield takeEvery(GET_REQUEST_SETTINGS, fetchData)
  yield takeEvery(UPDATE_REQUEST_SETTINGS, fetchData)
}

export default dataSaga
