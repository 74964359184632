import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'
import {
  DELETE_DIGITAL_REQUEST,
  GET_DIGITAL_REQUEST,
  GET_DIGITAL_REQUESTS,
  UPDATE_DIGITAL_REQUEST,
} from 'setup/redux/Actions/DigitalRequest/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_DIGITAL_REQUESTS, fetchData)
  yield takeEvery(GET_DIGITAL_REQUEST, fetchData)
  yield takeEvery(UPDATE_DIGITAL_REQUEST, fetchData)
  yield takeEvery(DELETE_DIGITAL_REQUEST, fetchData)
}

export default dataSaga
