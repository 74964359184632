/* eslint-disable */
let mixed = {
  default: '${path} est invalide',
  required: '${path} est requis',
  oneOf: "${path} doit être l'une des valeurs suivantes : ${values}",
  notOneOf: "${path} ne doit pas être l'une des valeurs suivantes : ${values}",
  notType: ({path, type, value, originalValue}) => {
    // let isCast = originalValue != null && originalValue !== value
    let msg = `${path} doit être de type \`${type}\``
    // + `mais la valeur finale était : \`${(0, _printValue.default)(value, true)}\`` +
    // (isCast
    //   ? ` (converti de la valeur \`${(0, _printValue.default)(originalValue, true)}\`).`
    //   : '.')

    // if (value === null) {
    //   msg += `\n Si "null" est destiné à être une valeur vide, assurez-vous de marquer le schéma comme \`.nullable()\``
    // }

    return msg
  },
  defined: '${path} doit être défini',
}

let string = {
  length: '${path} doit être exactement de ${length} caractères',
  min: '${path} doit être au moins de ${min} caractères',
  max: '${path} doit être au plus de ${max} caractères',
  matches: '${path} doit correspondre à : "${regex}"',
  email: '${path} doit être un email valide',
  url: '${path} doit être une URL valide',
  uuid: '${path} doit être un UUID valide',
  trim: '${path} doit être une chaîne sans espaces inutiles',
  lowercase: '${path} doit être une chaîne en minuscules',
  uppercase: '${path} doit être une chaîne en majuscules',
}

let number = {
  min: '${path} doit être supérieur ou égal à ${min}',
  max: '${path} doit être inférieur ou égal à ${max}',
  lessThan: '${path} doit être inférieur à ${less}',
  moreThan: '${path} doit être supérieur à ${more}',
  positive: '${path} doit être un nombre positif',
  negative: '${path} doit être un nombre négatif',
  integer: '${path} doit être un entier',
}

let date = {
  min: '${path} doit être postérieur à ${min}',
  max: '${path} doit être antérieur à ${max}',
}

let boolean = {
  isValue: '${path} doit être ${value}',
}

let object = {
  noUnknown: '${path} contient des clés non spécifiées : ${unknown}',
}

let array = {
  min: '${path} doit avoir au moins ${min} éléments',
  max: '${path} doit avoir au plus ${max} éléments',
  length: '${path} doit avoir ${length} éléments',
}

const fr = Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
})

export default fr
