export const GET_REQUESTS = 'GET_REQUESTS'
export const GET_REQUEST = 'GET_REQUEST'
export const ADD_REQUEST = 'ADD_REQUEST'
export const UPDATE_REQUEST = 'UPDATE_REQUEST'
export const DELETE_REQUEST = 'DELETE_REQUEST'

export const GET_REQUEST_SIGNED_UPLOAD_URL = 'GET_REQUEST_SIGNED_UPLOAD_URL'
export const CONFIRM_REQUEST_FILE_UPLOAD = 'CONFIRM_REQUEST_FILE_UPLOAD'
export const DELETE_REQUEST_ATTACHMENT = 'DELETE_REQUEST_ATTACHMENT'

export const GET_REQUEST_SETTINGS = 'GET_REQUEST_SETTINGS'
export const UPDATE_REQUEST_SETTINGS = 'UPDATE_REQUEST_SETTINGS'
