import Constants from 'common/constants'
import {
  GET_ADMISSION_SETTINGS,
  GET_COMPREHANSIVE_SETTINGS,
  GET_GENERAL_SETTINGS,
  GET_ID_CARD_SETTINGS,
  GET_OUTLINE_SETTINGS,
  GET_PREDICTED_SETTINGS,
  GET_STAFF_ID_CARD_SETTINGS,
  UPDATE_ENABLE_ADMISSION_FORM_SETTING,
  UPDATE_GENERAL_SETTINGS,
} from './ActionTypes/ApiActionTypes'
import {REDUX_GENERAL_SETTINGS} from './ActionTypes/ReduxActionTypes'

export const getGeneralSettings = (onSuccess, onFailure) => {
  return {
    type: GET_GENERAL_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_general_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: REDUX_GENERAL_SETTINGS,
    },
  }
}

export const updateGeneralSettings = (body, onSuccess, onFailure) => {
  return {
    type: UPDATE_GENERAL_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      body: body,
      apiUrl: Constants.api_general_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'PATCH',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getIdCardSettings = (onSuccess, onFailure) => {
  return {
    type: GET_ID_CARD_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_id_card_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getStaffIdCardSettings = (onSuccess, onFailure) => {
  return {
    type: GET_STAFF_ID_CARD_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_staff_id_card_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getOutlineSettings = (onSuccess, onFailure) => {
  return {
    type: GET_OUTLINE_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_outline_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getComprehansiveReportSettings = (onSuccess, onFailure) => {
  return {
    type: GET_COMPREHANSIVE_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_comprehansive_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getPredictedReportSettings = (onSuccess, onFailure) => {
  return {
    type: GET_PREDICTED_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_predicted_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getEarServices = (onSuccess, onFailure) => {
  return {
    type: GET_PREDICTED_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_ear_services,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getAdmissionSettings = (onSuccess, onFailure) => {
  return {
    type: GET_ADMISSION_SETTINGS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_admission_settings,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const updateEnableAdmissionFormSetting = (body, onSuccess, onFailure) => {
  return {
    type: UPDATE_ENABLE_ADMISSION_FORM_SETTING,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_update_enable_admission_form,
      getString: '',
      body,
      useBaseURL: true,
      requestType: 'PUT',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}
