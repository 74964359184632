/* eslint-disable */
let mixed = {
  default: '${path} غير صالح',
  required: '${path} مطلوب',
  oneOf: '${path} يجب أن تكون إحدى القيم التالية: ${values}',
  notOneOf: '${path} يجب ألا تكون إحدى القيم التالية: ${values}',
  notType: ({path, type, value, originalValue}) => {
    // let isCast = originalValue != null && originalValue !== value
    let msg = `${path} يجب أن يكون \`${type}\` اكتب, `
    // + `but the final value was: \`${(0, _printValue.default)(value, true)}\`` +
    // (isCast
    //   ? ` (cast from the value \`${(0, _printValue.default)(originalValue, true)}\`).`
    //   : '.')

    // if (value === null) {
    //   msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``
    // }

    return msg
  },
  defined: '${path} يجب تحديده',
}

let string = {
  length: '${path} يجب أن يكون بالضبط ${length} الشخصيات',
  min: '${path} لا بد أن يكون على الأقل ${min} الشخصيات',
  max: '${path} يجب أن يكون على الأكثر ${max} الشخصيات',
  matches: '${path} يجب أن تتطابق مع ما يلي: "${regex}"',
  email: '${path} يجب أن يكون بريدًا إلكترونيًا صالحًا',
  url: '${path} يجب أن يكون عنوان URL صالحًا',
  uuid: '${path} يجب أن يكون UUID صالحًا',
  trim: '${path} يجب أن يكون سلسلة مقطوعة',
  lowercase: '${path} يجب أن تكون سلسلة أحرف صغيرة',
  uppercase: '${path} يجب أن يكون سلسلة أحرف كبيرة',
}

let number = {
  min: '${path} يجب أن يكون أكبر من أو يساوي ${min}',
  max: '${path} يجب أن يكون أصغر من أو يساوي ${max}',
  lessThan: '${path} يجب أن يكون أقل من ${less}',
  moreThan: '${path} يجب أن يكون أكبر من ${more}',
  positive: '${path} يجب أن يكون رقما موجبا',
  negative: '${path} يجب أن يكون رقمًا سالبًا',
  integer: '${path} يجب أن يكون صحيحا',
}

let date = {
  min: '${path} يجب أن يكون الحقل بعد ${min}',
  max: '${path} يجب أن يكون الحقل قبل ${max}',
}

let boolean = {
  isValue: '${path} يجب أن يكون المجال ${value}',
}

let object = {
  noUnknown: '${path} يحتوي الحقل على مفاتيح غير محددة: ${unknown}',
}

let array = {
  min: '${path} يجب أن يحتوي الحقل على الأقل ${min} العناصر',
  max: '${path} يجب أن يحتوي الحقل على أقل من أو يساوي ${max} العناصر',
  length: '${path} يجب ان يملك ${length} العناصر',
}

const ar = Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
})

export default ar
