import ReactDOM from 'react-dom'
// Redux
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import store, {persistor} from 'setup/redux/Store'

// charts
import {Chart, registerables} from 'chart.js'

// Quill
import {Quill} from 'react-quill'
import ImageUploader from 'quill-image-uploader'

// Apps
import {MetronicI18nProvider} from 'common/i18n/Metronici18n'

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/

// import '_metronic/assets/sass/style.scss'
import '_metronic/assets/sass/style.react.scss'

import '_metronic/assets/css/urdu-fonts.css'
import 'react-toastify/dist/ReactToastify.css'

import 'bootstrap'

import {QueryClient, QueryClientProvider} from 'react-query'
import App from './app'

Chart.register(...registerables)
const queryClient = new QueryClient()
Quill.register('modules/imageUploader', ImageUploader)

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </MetronicI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)

window.onerror = (msg, url, line, col, error) => {
  // Note that col & error are new to the HTML 5 spec and may not be
  // supported in every browser.  It worked for me in Chrome.
  let extra = !col ? '' : '\ncolumn: ' + col
  extra += !error ? '' : '\nerror: ' + error
  let link = window.location.href

  if (link.indexOf('localhost') !== 1) return true
  // You can view the information in an alert to see things working like this:
  const errorLog =
    '\nendpoint: ' + link + '\nError: ' + msg + '\nurl: ' + url + '\nline: ' + line + extra
  console.log(errorLog)
  const xhttp = new XMLHttpRequest()
  xhttp.open(
    'POST',
    'https://hooks.slack.com/services/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'
  )
  // xhttp.setRequestHeader('Content-type', 'application/json')
  xhttp.send('{"text":"' + errorLog + '"}')
  var suppressErrorAlert = true
  return suppressErrorAlert
}

window.onunhandledrejection = (e: PromiseRejectionEvent) => {
  console.error(e)
  throw new Error(e.reason.stack)
}
