import {combineReducers} from 'redux'
import mainReducer from './mainReducer'
import userReducer from './userReducer'
import notificationsReducer from './notificationsReducer'

const rootReducer = combineReducers({
  mainReducer,
  notificationsReducer,
  userReducer,
})

// export type RootState = ReturnType<typeof rootReducer>
export interface RootState {
  mainReducer: ReturnType<typeof mainReducer>
  userReducer: ReturnType<typeof userReducer>
  notificationsReducer: ReturnType<typeof notificationsReducer>
}

export type MainReducer = ReturnType<typeof mainReducer>
export type UserReducer = ReturnType<typeof userReducer>
export type NotificationReducer = ReturnType<typeof notificationsReducer>

export default rootReducer
