import {takeEvery} from 'redux-saga/effects'
import {
  CREATE_THREAD,
  GET_THREADS,
  GET_THREAD_MESSAGES,
  READ_THREAD_MESSAGES,
  SEND_THREAD_MESSAGE,
} from 'setup/redux/Actions/Thread/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_THREADS, fetchData)
  yield takeEvery(CREATE_THREAD, fetchData)
  yield takeEvery(GET_THREAD_MESSAGES, fetchData)
  yield takeEvery(READ_THREAD_MESSAGES, fetchData)
  yield takeEvery(SEND_THREAD_MESSAGE, fetchData)
}

export default dataSaga
