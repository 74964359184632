import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'
import {
  LOAD_FORM_DATA,
  UPDATE_FORM_DATA,
  LOAD_MAPPINGS,
} from 'setup/redux/Actions/CustomNames/ActionTypes/ApiActionTypes'
function* dataSaga() {
  yield takeEvery(LOAD_FORM_DATA, fetchData)
  yield takeEvery(UPDATE_FORM_DATA, fetchData)
  yield takeEvery(LOAD_MAPPINGS, fetchData)
}

export default dataSaga
