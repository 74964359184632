import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, FieldArray, Form, Formik, FormikValues} from 'formik'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {
  confirmFileUpload,
  deleteOldCnicFiles,
  getSignedUrl,
  getVendor,
  registerVendor,
} from 'setup/redux/Actions/Vendor/vendor'
import {useParams} from 'react-router-dom'
import CustomField from 'common/components/CustomField'
import CustomRadio from 'common/components/CustomRadio'
import Spinner from 'common/components/Spinner'
import {toAbsoluteUrl} from '_metronic/helpers'
import {useTheme} from 'common/themes/ThemeHelpers'
import CustomFileFiled from 'common/components/CustomFileField'
import axios from 'axios'
import {toast} from 'react-toastify'
import {getGeneralSettings} from 'setup/redux/Actions/General/settings'

const VendorRegisterForm: FC = () => {
  const intl = useIntl()
  const {id} = useParams()
  const dispatch = useDispatch()

  const generalSettings = useSelector<any>(({mainReducer}) => mainReducer.generalSettings) as any
  const theme = useTheme()

  const [vendor, setVendor] = useState<any>(null)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [tenantLogo, setTenantLogo] = useState(generalSettings?.bigLogo)

  const schema = Yup.object().shape({
    documentList: Yup.object()
      .shape({
        form_c: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.FORM_C_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.FORM_C_COPY'}))
          ),
          remarks: Yup.string(),
        }),
        cnic_copy: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.CNIC_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.CNIC_COPY'}))
          ),
          remarks: Yup.string(),
        }),
        ntn_copy: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.NTN_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.NTN_COPY'}))
          ),
          remarks: Yup.string(),
        }),
        sales_tax_copy: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.SALES_TAX_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.SALES_TAX_COPY'}))
          ),
          remarks: Yup.string(),
        }),
        company_profile: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.COMPANY_PROFILE_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.COMPANY_PROFILE_COPY'}))
          ),
          remarks: Yup.string(),
        }),
        product_services: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.PRODUCT_SERVICES_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.PRODUCT_SERVICES_COPY'}))
          ),
          remarks: Yup.string(),
        }),
        financial_statement: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.FINANCIAL_STATEMENT_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.FINANCIAL_STATEMENT_COPY'}))
          ),
          remarks: Yup.string(),
        }),
        bank_statement: Yup.object().shape({
          value: Yup.lazy((value) =>
            typeof value === 'object'
              ? Yup.mixed()
                  .nullable()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.BANK_STATEMENT_COPY'}))
              : Yup.string()
                  .required()
                  .label(intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.BANK_STATEMENT_COPY'}))
          ),
          remarks: Yup.string(),
        }),
      })
      .required(),
    section1: Yup.object()
      .shape({
        name: Yup.string()
          .required()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.NAME'})),
        ntn: Yup.string()
          .min(7)
          .max(20)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.NTN'})),
        strn: Yup.string()
          .max(20)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.STRN'})),
        address: Yup.string()
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.ADDRESS'})),
        city: Yup.string()
          .max(100)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.CITY'})),
        country: Yup.string()
          .max(100)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.COUNTRY'})),
        contact_name: Yup.string()
          .max(100)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.CONTACT_NAME'})),
        contact_designation: Yup.string()
          .max(100)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.CONTACT_DESIGNATION'})),
        telephone: Yup.string()
          .max(25)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.TELEPHONE'})),
        mobile: Yup.string()
          .max(25)
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.MOBILE'})),
        email: Yup.string()
          .email()
          .max(100)
          .required()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_1.EMAIL'})),
      })
      .required(),
    section2: Yup.object()
      .shape({
        company_type: Yup.string()
          .nullable()
          .label(intl.formatMessage({id: 'VENDORS.SECTION_2.COMPANY_TYPE'})),
      })
      .required(),
    section3: Yup.object().shape({
      owners: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_3.OWNERS.NAME'})),
          cnic: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_3.OWNERS.CNIC'})),
          share: Yup.number()
            .min(10)
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_3.OWNERS.SHARE'})),
        })
      ),
    }),
    section4: Yup.object().shape({
      authorized_personnel: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.NAME'})),
          designation: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.DESIGNATION'})),
          contact: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.CONTACT'})),
          signature: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.SIGNATURE'})),
        })
      ),
    }),
    section5: Yup.object().shape({
      management_details: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_5.MANAGEMENT_DETAILS.NAME'})),
          designation: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_5.MANAGEMENT_DETAILS.DESIGNATION'})),
          contact: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_5.MANAGEMENT_DETAILS.CONTACT'})),
          signature: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_5.MANAGEMENT_DETAILS.SIGNATURE'})),
        })
      ),
    }),
    section6: Yup.object().shape({
      bank_details: Yup.array().of(
        Yup.object().shape({
          account_title: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_6.BANK_DETAILS.ACCOUNT_TITLE'})),
          account_number: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_6.BANK_DETAILS.ACCOUNT_NUMBER'})),
          bank_name: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_6.BANK_DETAILS.BANK_NAME'})),
          bank_address: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_6.BANK_DETAILS.BANK_ADDRESS'})),
          bank_branch_code: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_6.BANK_DETAILS.BANK_BRANCH_CODE'})),
        })
      ),
    }),
    section7: Yup.object().shape({
      industry_experience: Yup.number()
        .integer()
        .positive()
        .label(intl.formatMessage({id: 'VENDORS.SECTION_7.INDUSTRY_EXPERIENCE'})),
      experience: Yup.array().of(
        Yup.object().shape({
          client: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_7.EXPERIENCE.CLIENT'})),
          contact: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_7.EXPERIENCE.CONTACT'})),
          business_value: Yup.number()
            .positive()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_7.EXPERIENCE.BUSINESS_VALUE'})),
          service_product: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_7.EXPERIENCE.SERVICE_PRODUCT'})),
        })
      ),
    }),
    section8: Yup.object().shape({
      skilled_employees: Yup.object()
        .shape({
          executive_c_level: Yup.number()
            .integer()
            .positive()
            .nullable()
            .label(
              intl.formatMessage({id: 'VENDORS.SECTION_8.SKILLED_EMPLOYEES.EXECUTIVE_C_LEVEL'})
            ),
          management_executive_support: Yup.number()
            .integer()
            .positive()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'VENDORS.SECTION_8.SKILLED_EMPLOYEES.MANAGEMENT_EXECUTIVE_SUPPORT',
              })
            ),
          technical: Yup.number()
            .integer()
            .positive()
            .nullable()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_8.SKILLED_EMPLOYEES.TECHNICAL'})),
          total_skilled_employees: Yup.number()
            .integer()
            .positive()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'VENDORS.SECTION_8.SKILLED_EMPLOYEES.TOTAL_SKILLED_EMPLOYEES',
              })
            ),
          total_employees: Yup.number()
            .integer()
            .positive()
            .nullable()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_8.SKILLED_EMPLOYEES.TOTAL_EMPLOYEES'})),
        })
        .required(),
    }),
    section9: Yup.object().shape({
      emergency_support: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_9.EMERGENCY_SUPPORT.NAME'})),
          contact: Yup.string()
            .required()
            .label(intl.formatMessage({id: 'VENDORS.SECTION_9.EMERGENCY_SUPPORT.CONTACT'})),
          qualification: Yup.string().label(
            intl.formatMessage({id: 'VENDORS.SECTION_9.EMERGENCY_SUPPORT.QUALIFICATION'})
          ),
        })
      ),
    }),
  })

  const uploadVendorDocument = (
    file: File | FileList,
    docType:
      | 'form_c'
      | 'cnic_copy'
      | 'ntn_copy'
      | 'sales_tax_copy'
      | 'company_profile'
      | 'product_services'
      | 'financial_statement'
      | 'bank_statement',
    onSuccess: (res: any) => void,
    onFailure: (err: any) => void
  ) => {
    const uploadFileWithConfirmation = (
      file: File,
      docType:
        | 'form_c'
        | 'cnic_copy'
        | 'ntn_copy'
        | 'sales_tax_copy'
        | 'company_profile'
        | 'product_services'
        | 'financial_statement'
        | 'bank_statement',
      onSuccess: (res: any) => void,
      onFailure: (err: any) => void
    ) => {
      if (file instanceof File) {
        const lastIndex = file.name.lastIndexOf('.')
        const fileName = file.name.slice(0, lastIndex)
        const body = {
          vendorId: id,
          fileName,
          contentType: file.type,
        }
        dispatch(
          getSignedUrl(
            body,
            (res: any) => {
              const {fileUrl, uploadURL} = res.result

              dispatch(
                deleteOldCnicFiles(
                  id,
                  (res: any) => {
                    file.arrayBuffer().then((buffer: any) => {
                      toast.info(`Uploading ${file.name}`)
                      axios
                        .put(uploadURL, buffer)
                        .then((r) => {
                          const body = {
                            vendorId: id,
                            original_filename: file.name,
                            file_name: fileUrl.split('/').slice(-1).pop(),
                            url: fileUrl,
                            type: file.type,
                            size: file.size,
                            file_type: docType,
                          }

                          dispatch(
                            confirmFileUpload(
                              body,
                              (res: any) => {
                                toast.success(`${file.name} uploaded successfully`)
                                if (onSuccess) onSuccess(res)
                              },
                              onFailure
                            )
                          )
                        })
                        .catch(onFailure)
                    })
                  },
                  onFailure
                )
              )
            },
            onFailure
          )
        )
      }
    }
    // eslint-disable-next-line eqeqeq
    if (file instanceof FileList && docType == 'cnic_copy') {
      for (let i = 0; i < file.length; i++) {
        const f = file[i]
        uploadFileWithConfirmation(f, docType, onSuccess, onFailure)
      }
    } else if (file instanceof File) {
      uploadFileWithConfirmation(file, docType, onSuccess, onFailure)
    }
  }

  const submit = (values: any, actions: FormikValues) => {
    const data = {...values}
    // initialValues = values

    actions.setSubmitting(true)
    setError('')
    setSuccess(false)

    const onSuccess = (res: any) => {
      setSuccess(true)
      actions.setSubmitting(false)
      window.scrollTo({top: 0})
    }

    const onFileUploadSuccess = (res: any) => {
      // actions.setSubmitting(false)
      // window.scrollTo({top: 0})
    }

    const onFailure = (err: any) => {
      setError(err?.response?.data?.message ?? err?.message ?? 'Something went wrong...')
      actions.setSubmitting(false)
      window.scrollTo({top: 0})
    }

    // form_c
    uploadVendorDocument(values.documentList.form_c.value, 'form_c', onFileUploadSuccess, onFailure)
    // cnic_copy
    uploadVendorDocument(
      values.documentList.cnic_copy.value,
      'cnic_copy',
      onFileUploadSuccess,
      onFailure
    )
    // ntn_copy
    uploadVendorDocument(
      values.documentList.ntn_copy.value,
      'ntn_copy',
      onFileUploadSuccess,
      onFailure
    )
    // sales_tax_copy
    uploadVendorDocument(
      values.documentList.sales_tax_copy.value,
      'sales_tax_copy',
      onFileUploadSuccess,
      onFailure
    )
    // company_profile
    uploadVendorDocument(
      values.documentList.company_profile.value,
      'company_profile',
      onFileUploadSuccess,
      onFailure
    )
    // product_services
    uploadVendorDocument(
      values.documentList.product_services.value,
      'product_services',
      onFileUploadSuccess,
      onFailure
    )
    // financial_statement
    uploadVendorDocument(
      values.documentList.financial_statement.value,
      'financial_statement',
      onFileUploadSuccess,
      onFailure
    )
    // bank_statement
    uploadVendorDocument(
      values.documentList.bank_statement.value,
      'bank_statement',
      onFileUploadSuccess,
      onFailure
    )

    dispatch(registerVendor(id, data, onSuccess, onFailure))
  }

  const loadVendor = () => {
    const onSuccess = (res: any) => {
      setVendor(res.result)
    }

    const onFailure = (err: any) => {
      const message = err?.response?.data?.message ?? err?.message ?? 'Error Loading VendorS...'
      setError(message)
    }

    dispatch(getVendor(id, onSuccess, onFailure))
  }

  const checkIfLogoExists = () => {
    let tl = generalSettings?.bigLogo

    if (tl) {
      axios
        .get(tl)
        .then((res) => {
          setTenantLogo(generalSettings?.bigLogo)
        })
        .catch((err) => {
          setTenantLogo(tl.replace('ilmversityuploads', 'ilmversity-uploads'))
        })
    }
  }

  useEffect(() => {
    if (!generalSettings || Object.keys(generalSettings).length < 1) {
      dispatch(
        getGeneralSettings((res: any) => {
          checkIfLogoExists()
        })
      )
    } else {
      checkIfLogoExists()
    }

    loadVendor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkIfLogoExists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSettings])

  return (
    <div className='card'>
      {vendor && (
        <Formik
          validationSchema={schema}
          initialValues={vendor}
          onSubmit={submit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form>
              <div className='card-body'>
                <div className='pb-3 text-center'>
                  <img
                    alt='Logo'
                    src={
                      tenantLogo ??
                      (theme === 'light'
                        ? toAbsoluteUrl('/media/logos/logo-1.svg')
                        : toAbsoluteUrl('/media/logos/logo-1-dark.svg'))
                    }
                    className={tenantLogo ? '' : 'h-80x'}
                    style={{maxHeight: '120px'}}
                  />
                  {/* {intl.formatMessage({id: 'VENDORS.REGISTER_VENDOR'})} */}
                  <h2>Complete Your Profile</h2>
                </div>

                {error && (
                  <div className='alert alert-danger' role='alert'>
                    {error}
                  </div>
                )}
                {success && (
                  <div className='alert alert-success' role='alert'>
                    Vendor Registered...
                  </div>
                )}

                <div className='alert alert-primary fs-3' style={{textAlign: 'justify'}}>
                  <ol>
                    <li className='mb-2'>
                      All new Vendors are required to complete this form and submit it along with
                      the documents mentioned in the checklist stipulated below. If any of the
                      documents are not included with the form, please state the reason in the
                      remarks section.
                    </li>
                    <li className='mb-2'>
                      All sections of this form must be completed and all requirements must be fully
                      complied with. Incomplete forms will not be processed.
                    </li>
                    <li className='mb-2'>
                      Where a question is not relevant please enter NA (Not applicable).
                    </li>
                    <li className='mb-2'>
                      Additional Information may be attached on separate sheet(s).
                    </li>
                    <li className='mb-2'>
                      Registration with The Lyceum, if accepted, does not constitute any obligation
                      for The Lyceum to guarantee any Bidding invitation, contractual awards or any
                      order for products or services.
                    </li>
                    <li className='mb-2'>
                      All queries regarding registration should be directed to the Admin &
                      Procurement Department of The Lyceum.
                    </li>
                  </ol>
                </div>

                <div className='mb-3'>
                  <h3>{intl.formatMessage({id: 'VENDORS.DOCUMENT_LIST.HEADING'})}</h3>

                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder'>
                          <th className='ps-2'>Sr. No.</th>
                          <th className='ps-2 w-450px'>Document</th>
                          <th className='ps-2 min-w-150px w-200px'>Attachment</th>
                          <th className='ps-2 min-w-200px'>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1.</td>
                          <td>
                            Incorporation Certificates or Firm Registration Certificate or Form C
                          </td>
                          <td>
                            {/* <CustomSwitch name='documentList.form_c.value' /> */}
                            <CustomFileFiled
                              name='documentList.form_c.value'
                              value={formik.values.documentList.form_c.value}
                              accept='application/pdf,image/*'
                            />
                          </td>
                          <td>
                            <CustomField name='documentList.form_c.remarks' type='text' />
                          </td>
                        </tr>

                        <tr>
                          <td>2.</td>
                          <td>Copy of CNIC of Owners/Board of Directors</td>
                          <td>
                            {/* <CustomSwitch name='documentList.cnic_copy.value' /> */}
                            <CustomFileFiled
                              name='documentList.cnic_copy.value'
                              value={formik.values.documentList.cnic_copy.value}
                              accept='application/pdf,image/*'
                              multiple
                            />
                          </td>
                          <td>
                            <CustomField name='documentList.cnic_copy.remarks' type='text' />
                          </td>
                        </tr>

                        <tr>
                          <td>3.</td>
                          <td>Colour copy of National Tax Number Certificate</td>
                          <td>
                            {/* <CustomSwitch name='documentList.ntn_copy.value' /> */}
                            <CustomFileFiled
                              name='documentList.ntn_copy.value'
                              value={formik.values.documentList.ntn_copy.value}
                              accept='application/pdf,image/*'
                            />
                          </td>
                          <td>
                            <CustomField name='documentList.ntn_copy.remarks' type='text' />
                          </td>
                        </tr>

                        <tr>
                          <td>4.</td>
                          <td>Colour copy of Sales Tax Registration Certificate</td>
                          <td>
                            {/* <CustomSwitch name='documentList.sales_tax_copy.value' /> */}
                            <CustomFileFiled
                              name='documentList.sales_tax_copy.value'
                              value={formik.values.documentList.sales_tax_copy.value}
                              accept='application/pdf,image/*'
                            />
                          </td>
                          <td>
                            <CustomField name='documentList.sales_tax_copy.remarks' type='text' />
                          </td>
                        </tr>

                        <tr>
                          <td>5.</td>
                          <td>
                            Company Profile with past experience. Details of major supplies /
                            contracts / services / projects, over the last two years with values and
                            corresponding client names along with work evidence
                          </td>
                          <td>
                            {/* <CustomSwitch name='documentList.company_profile.value' /> */}
                            <CustomFileFiled
                              name='documentList.company_profile.value'
                              value={formik.values.documentList.company_profile.value}
                              accept='application/pdf,image/*'
                            />
                          </td>
                          <td>
                            <CustomField name='documentList.company_profile.remarks' type='text' />
                          </td>
                        </tr>

                        <tr>
                          <td>6.</td>
                          <td>List of Products and Services you are able to provide.</td>
                          <td>
                            {/* <CustomSwitch name='documentList.product_services.value' /> */}
                            <CustomFileFiled
                              name='documentList.product_services.value'
                              value={formik.values.documentList.product_services.value}
                              accept='application/pdf,image/*'
                            />
                          </td>
                          <td>
                            <CustomField name='documentList.product_services.remarks' type='text' />
                          </td>
                        </tr>

                        <tr>
                          <td>7.</td>
                          <td>Latest Financial Statement along with Audited Report</td>
                          <td>
                            {/* <CustomSwitch name='documentList.financial_statement.value' /> */}
                            <CustomFileFiled
                              name='documentList.financial_statement.value'
                              value={formik.values.documentList.financial_statement.value}
                              accept='application/pdf,image/*'
                            />
                          </td>
                          <td>
                            <CustomField
                              name='documentList.financial_statement.remarks'
                              type='text'
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>8.</td>
                          <td>Latest one-year bank Statement</td>
                          <td>
                            {/* <CustomSwitch name='documentList.bank_statement.value' /> */}
                            <CustomFileFiled
                              name='documentList.bank_statement.value'
                              value={formik.values.documentList.bank_statement.value}
                              accept='application/pdf,image/*'
                            />
                          </td>
                          <td>
                            <CustomField name='documentList.bank_statement.remarks' type='text' />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr style={{marginTop: '3rem', marginBottom: '5rem'}} />

                <div className='mb-5 pb-5'>
                  <h3 className='mb-5'>{intl.formatMessage({id: 'VENDORS.SECTION_1.HEADING'})}</h3>

                  <CustomField name='section1.name' type='text' label='Name of Vendor' required />

                  <CustomField name='section1.ntn' type='text' label='VENDORS.SECTION_1.NTN' />

                  <CustomField name='section1.strn' type='text' label='VENDORS.SECTION_1.STRN' />

                  <CustomField
                    name='section1.address'
                    type='text'
                    label='VENDORS.SECTION_1.ADDRESS'
                  />

                  <CustomField name='section1.city' type='text' label='VENDORS.SECTION_1.CITY' />

                  <CustomField
                    name='section1.country'
                    type='text'
                    label='VENDORS.SECTION_1.COUNTRY'
                  />

                  <CustomField
                    name='section1.contact_name'
                    type='text'
                    label='VENDORS.SECTION_1.CONTACT_NAME'
                  />

                  <CustomField
                    name='section1.contact_designation'
                    type='text'
                    label='VENDORS.SECTION_1.CONTACT_DESIGNATION'
                  />

                  <CustomField
                    name='section1.telephone'
                    type='text'
                    label='VENDORS.SECTION_1.TELEPHONE'
                  />

                  <CustomField
                    name='section1.mobile'
                    type='text'
                    label='VENDORS.SECTION_1.MOBILE'
                  />

                  <CustomField
                    name='section1.email'
                    type='text'
                    label='VENDORS.SECTION_1.EMAIL'
                    required
                  />
                </div>

                <div className='mb-5 pb-5'>
                  <h3 className='mb-5'>{intl.formatMessage({id: 'VENDORS.SECTION_2.HEADING'})}</h3>

                  <CustomRadio
                    name='section2.company_type'
                    label='Registered Company'
                    value='registered'
                  />
                  <CustomRadio
                    name='section2.company_type'
                    label='Partnership'
                    value='partnership'
                  />
                  <CustomRadio
                    name='section2.company_type'
                    label='Sole Proprietorship'
                    value='sole_proprietorship'
                  />
                  <CustomRadio
                    name='section2.company_type'
                    label='Government Entity'
                    value='government_entity'
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='section2.company_type' />
                  </div>
                </div>

                <div className='mb-5 pb-5'>
                  <FieldArray
                    name='section3.owners'
                    render={(arrayHelper) => (
                      <>
                        <h3 className='mb-5 pb-5 row'>
                          <div className='col-6 col-sm-8'>
                            {intl.formatMessage({id: 'VENDORS.SECTION_3.HEADING'})}
                          </div>
                          <div className='col-6 col-sm-4'>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary float-end'
                              onClick={() => {
                                arrayHelper.push({name: '', cnic: '', share: 10})
                              }}
                              title={intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            >
                              <span className='bi bi-plus-lg' />{' '}
                              {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            </button>
                          </div>
                        </h3>
                        <div className='alert alert-primary' role='alert'>
                          PLEASE SPECIFY OWNERS / PARTNERS / SHAREHOLDERS (WITH 10% OR ABOVE
                          SHAREHOLDING) NAMES WITH SHARE PERCENTAGE.
                        </div>
                        {formik.values.section3.owners.map((owner: any, i: number) => (
                          <div className='row' key={i}>
                            <div className='col-sm-12 col-md-1 pt-5'>Mr./Mrs.</div>
                            <div className='col-sm-12 col-md-4'>
                              <CustomField
                                label='VENDORS.SECTION_3.OWNERS.NAME'
                                name={`section3.owners[${i}].name`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-4'>
                              <CustomField
                                label='VENDORS.SECTION_3.OWNERS.CNIC'
                                name={`section3.owners[${i}].cnic`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-2'>
                              <CustomField
                                label='VENDORS.SECTION_3.OWNERS.SHARE'
                                name={`section3.owners[${i}].share`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-1 pt-2'>
                              <button
                                className='btn btn-sm btn-icon btn-danger float-end'
                                type='button'
                                onClick={() => {
                                  // if (formik.values.section3.owners.length > 1)
                                  arrayHelper.remove(i)
                                }}
                                // disabled={formik.values.section3.owners.length <= 1 && i == 0}
                              >
                                <span className='bi bi-trash'></span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>

                <div className='mb-5 pb-5'>
                  <FieldArray
                    name='section4.authorized_personnel'
                    render={(arrayHelper) => (
                      <>
                        <h3 className='mb-5 pb-5 row'>
                          <div className='col-6 col-sm-8'>
                            {intl.formatMessage({id: 'VENDORS.SECTION_4.HEADING'})}
                          </div>
                          <div className='col-6 col-sm-4'>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary float-end'
                              onClick={() => {
                                arrayHelper.push({
                                  name: '',
                                  designation: '',
                                  contact: '',
                                  signature: '',
                                })
                              }}
                              title={intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            >
                              <span className='bi bi-plus-lg' />{' '}
                              {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            </button>
                          </div>
                        </h3>
                        {formik.values.section4.authorized_personnel.map((ap: any, i: number) => (
                          <div className='row' key={i}>
                            <div className='col-sm-12 col-md-1 text-center fw-bolder pt-5'>
                              {i + 1}
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.NAME'
                                name={`section4.authorized_personnel[${i}].name`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.DESIGNATION'
                                name={`section4.authorized_personnel[${i}].designation`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-2'>
                              <CustomField
                                label='VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.CONTACT'
                                name={`section4.authorized_personnel[${i}].contact`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-2'>
                              <CustomField
                                label='VENDORS.SECTION_4.AUTHORIZED_PERSONNEL.SIGNATURE'
                                name={`section4.authorized_personnel[${i}].signature`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-1 pt-2'>
                              <button
                                className='btn btn-sm btn-icon btn-danger float-end'
                                type='button'
                                onClick={() => {
                                  // if (formik.values.section4.authorized_personnel.length > 1)
                                  arrayHelper.remove(i)
                                }}
                                // disabled={
                                //   formik.values.section4.authorized_personnel.length <= 1 && i == 0
                                // }
                              >
                                <span className='bi bi-trash'></span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>

                <div className='mb-5 pb-5'>
                  <FieldArray
                    name='section5.management_details'
                    render={(arrayHelper) => (
                      <>
                        <h3 className='mb-5 pb-5 row'>
                          <div className='col-6 col-sm-8'>
                            {intl.formatMessage({id: 'VENDORS.SECTION_5.HEADING'})}
                          </div>
                          <div className='col-6 col-sm-4'>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary float-end'
                              onClick={() => {
                                arrayHelper.push({
                                  name: '',
                                  designation: '',
                                  contact: '',
                                  signature: '',
                                })
                              }}
                              title={intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            >
                              <span className='bi bi-plus-lg' />{' '}
                              {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            </button>
                          </div>
                        </h3>
                        {formik.values.section5.management_details.map((md: any, i: number) => (
                          <div className='row' key={i}>
                            <div className='col-sm-12 col-md-1 text-center fw-bolder pt-5'>
                              {i + 1}
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_5.MANAGEMENT_DETAILS.NAME'
                                name={`section5.management_details[${i}].name`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_5.MANAGEMENT_DETAILS.DESIGNATION'
                                name={`section5.management_details[${i}].designation`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-2'>
                              <CustomField
                                label='VENDORS.SECTION_5.MANAGEMENT_DETAILS.CONTACT'
                                name={`section5.management_details[${i}].contact`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-2'>
                              <CustomField
                                label='VENDORS.SECTION_5.MANAGEMENT_DETAILS.SIGNATURE'
                                name={`section5.management_details[${i}].signature`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-1 pt-2'>
                              <button
                                className='btn btn-sm btn-icon btn-danger float-end'
                                type='button'
                                onClick={() => {
                                  // if (formik.values.section5.management_details.length > 1)
                                  arrayHelper.remove(i)
                                }}
                                // disabled={
                                //   formik.values.section5.management_details.length <= 1 && i == 0
                                // }
                              >
                                <span className='bi bi-trash'></span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>

                <div className='mb-5 pb-5'>
                  <FieldArray
                    name='section6.bank_details'
                    render={(arrayHelper) => (
                      <>
                        <h3 className='mb-5 pb-5 row'>
                          <div className='col-6 col-sm-8'>
                            {intl.formatMessage({id: 'VENDORS.SECTION_6.HEADING'})}
                          </div>
                          <div className='col-6 col-sm-4'>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary float-end'
                              onClick={() => {
                                arrayHelper.push({
                                  account_number: '',
                                  account_title: '',
                                  bank_address: '',
                                  bank_branch_code: '',
                                  bank_name: '',
                                })
                              }}
                              title={intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            >
                              <span className='bi bi-plus-lg' />{' '}
                              {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            </button>
                          </div>
                        </h3>
                        {formik.values.section6.bank_details.map((bd: any, i: number) => (
                          <div className='py-3 border-bottom' key={i}>
                            <h4>
                              Bank {i + 1}
                              <button
                                className='btn btn-sm btn-icon btn-danger float-end float-end'
                                type='button'
                                onClick={() => {
                                  // if (formik.values.section6.bank_details.length > 1)
                                  arrayHelper.remove(i)
                                }}
                                // disabled={formik.values.section6.bank_details.length <= 1 && i == 0}
                              >
                                <span className='bi bi-trash'></span>
                              </button>
                            </h4>
                            <div className='pt-5'>
                              <CustomField
                                label='VENDORS.SECTION_6.BANK_DETAILS.ACCOUNT_TITLE'
                                name={`section6.bank_details[${i}].account_title`}
                                type='text'
                                required
                              />
                              <CustomField
                                label='VENDORS.SECTION_6.BANK_DETAILS.ACCOUNT_NUMBER'
                                name={`section6.bank_details[${i}].account_number`}
                                type='text'
                                required
                              />
                              <CustomField
                                label='VENDORS.SECTION_6.BANK_DETAILS.BANK_NAME'
                                name={`section6.bank_details[${i}].bank_name`}
                                type='text'
                                required
                              />
                              <CustomField
                                label='VENDORS.SECTION_6.BANK_DETAILS.BANK_ADDRESS'
                                name={`section6.bank_details[${i}].bank_address`}
                                type='text'
                                required
                              />
                              <CustomField
                                label='VENDORS.SECTION_6.BANK_DETAILS.BANK_BRANCH_CODE'
                                name={`section6.bank_details[${i}].bank_branch_code`}
                                type='text'
                                required
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>

                <div className='mb-5 pb-5'>
                  <FieldArray
                    name='section7.experience'
                    render={(arrayHelper) => (
                      <>
                        <h3 className='mb-5 pb-5 row'>
                          <div className='col-6 col-sm-8'>
                            {intl.formatMessage({id: 'VENDORS.SECTION_7.HEADING'})}
                          </div>
                          <div className='col-6 col-sm-4'>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary float-end'
                              onClick={() => {
                                arrayHelper.push({
                                  client: '',
                                  contact: '',
                                  business_value: '',
                                  service_product: '',
                                })
                              }}
                              title={intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            >
                              <span className='bi bi-plus-lg' />{' '}
                              {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            </button>
                          </div>
                        </h3>
                        <div className='mt-xs-5 row'>
                          <div className='col-xs-12 col-sm-6 col-md-4'>
                            <CustomField
                              label='VENDORS.SECTION_7.INDUSTRY_EXPERIENCE'
                              name={`section7.industry_experience`}
                              type='text'
                            />
                          </div>
                        </div>
                        {formik.values.section7.experience.map((ex: any, i: number) => (
                          <div className='row' key={i}>
                            <div className='col-sm-12 col-md-1 text-center fw-bolder pt-5'>
                              {i + 1}
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_7.EXPERIENCE.CLIENT'
                                name={`section7.experience[${i}].client`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_7.EXPERIENCE.CONTACT'
                                name={`section7.experience[${i}].contact`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-2'>
                              <CustomField
                                label='VENDORS.SECTION_7.EXPERIENCE.BUSINESS_VALUE'
                                name={`section7.experience[${i}].business_value`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-2'>
                              <CustomField
                                label='VENDORS.SECTION_7.EXPERIENCE.SERVICE_PRODUCT'
                                name={`section7.experience[${i}].service_product`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-1 pt-2'>
                              <button
                                className='btn btn-sm btn-icon btn-danger float-end'
                                type='button'
                                onClick={() => {
                                  // if (formik.values.section7.experience.length > 1)
                                  arrayHelper.remove(i)
                                }}
                                // disabled={formik.values.section7.experience.length <= 1 && i == 0}
                              >
                                <span className='bi bi-trash'></span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>

                <div className='mb-3'>
                  <h3>{intl.formatMessage({id: 'VENDORS.SECTION_8.HEADING'})}</h3>

                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder'>
                          <th className='ps-2'>Sr. No.</th>
                          <th className='ps-2'>Skill Based Category</th>
                          <th className='ps-2'>Number of Employees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1.</td>
                          <td>Executive-C-Level</td>
                          <td>
                            <CustomField
                              name='section8.skilled_employees.executive_c_level'
                              type='text'
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>2.</td>
                          <td>Management Executive & Support </td>
                          <td>
                            <CustomField
                              name='section8.skilled_employees.management_executive_support'
                              type='text'
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>3.</td>
                          <td>Technical</td>
                          <td>
                            <CustomField name='section8.skilled_employees.technical' type='text' />
                          </td>
                        </tr>

                        <tr>
                          <td>4.</td>
                          <td>Total No of Skilled Employees</td>
                          <td>
                            <CustomField
                              name='section8.skilled_employees.total_skilled_employees'
                              type='text'
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>5.</td>
                          <td>Total Employees</td>
                          <td>
                            <CustomField
                              name='section8.skilled_employees.total_employees'
                              type='text'
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='mb-5 pb-5'>
                  <FieldArray
                    name='section9.emergency_support'
                    render={(arrayHelper) => (
                      <>
                        <h3 className='mb-5 pb-5 row'>
                          <div className='col-6 col-sm-8'>
                            {intl.formatMessage({id: 'VENDORS.SECTION_9.HEADING'})}
                          </div>
                          <div className='col-6 col-sm-4'>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary float-end'
                              onClick={() => {
                                arrayHelper.push({
                                  name: '',
                                  contact: '',
                                  qualification: '',
                                })
                              }}
                              title={intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            >
                              <span className='bi bi-plus-lg' />{' '}
                              {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                            </button>
                          </div>
                        </h3>
                        {formik.values.section9.emergency_support.map((es: any, i: number) => (
                          <div className='row' key={i}>
                            <div className='col-sm-12 col-md-1 text-center fw-bolder pt-5'>
                              {i + 1}
                            </div>
                            <div className='col-sm-12 col-md-4'>
                              <CustomField
                                label='VENDORS.SECTION_9.EMERGENCY_SUPPORT.NAME'
                                name={`section9.emergency_support[${i}].name`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_9.EMERGENCY_SUPPORT.CONTACT'
                                name={`section9.emergency_support[${i}].contact`}
                                type='text'
                                required
                              />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                              <CustomField
                                label='VENDORS.SECTION_9.EMERGENCY_SUPPORT.QUALIFICATION'
                                name={`section9.emergency_support[${i}].qualification`}
                                type='text'
                              />
                            </div>
                            <div className='col-sm-12 col-md-1 pt-2'>
                              <button
                                className='btn btn-sm btn-icon btn-danger float-end'
                                type='button'
                                onClick={() => {
                                  // if (formik.values.section9.emergency_support.length > 1)
                                  arrayHelper.remove(i)
                                }}
                                // disabled={
                                //   formik.values.section9.emergency_support.length <= 1 && i == 0
                                // }
                              >
                                <span className='bi bi-trash'></span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
              </div>

              <div className='card-footer bg-secondary d-flex align-items-center justify-content-center'>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary d-flex align-items-center float-center'
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting && (
                    <div className='spinner-grow spinner-grow-sm text-light me-1' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  )}
                  {intl.formatMessage({id: 'GENERAL.SAVE'})}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {!vendor && (
        <div className='d-flex justify-content-center'>
          <Spinner type='grow' color='primary' />
        </div>
      )}
    </div>
  )
}

export default VendorRegisterForm
