export const REQUEST_TYPES = {
  MAINTENANCE: 1,
  PURCHASES: 2,
}

export const REQUEST_STATUS = {
  REJECTED: 0,
  PENDING: 1,
  PENDING_APPROVAL_PRINCIPLE: 2,
  PENDING_APPROVAL_SENIOR_PRINCIPLE: 3,
  PENDING_APPROVAL_BOARD: 4,
  APPROVED: 5,
  COMPLETED_BY_STAFF: 6,
  COMPLETED: 7,
  PENDING_APPROVAL_PURCHASE_COMMITTEE: 8,
  PENDING_APPROVAL_SCHOOL_MANAGEMENT: 9,
  PENDING_APPROVAL_CHAIRMAN: 10,
  PENDING_PURCHASE_TYPE: 11,
  PENDING_ACCOUNTS_ADVANCE: 12,
}

export const REQUEST_PURCHASE_TYPE = {
  CREDIT: 0,
  ADVANCE: 1,
}
