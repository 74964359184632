import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'
import {
  GET_TAGS,
  GET_TAG,
  ADD_TAG,
  UPDATE_TAG,
  DELETE_TAG,
} from 'setup/redux/Actions/School/Tags/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_TAGS, fetchData)
  yield takeEvery(GET_TAG, fetchData)
  yield takeEvery(ADD_TAG, fetchData)
  yield takeEvery(UPDATE_TAG, fetchData)
  yield takeEvery(DELETE_TAG, fetchData)
}

export default dataSaga
