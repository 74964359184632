export const GET_STATS = 'GET_STATS'
export const GET_FEED = 'GET_FEED'

export const GET_TOTAL_USER = 'GET_TOTAL_USER'
export const GET_ACTIVE_USER = 'GET_ACTIVE_USER'
export const GET_ACTIVITIES_USER = 'GET_ACTIVITIES_USER'
export const GET_ATTENDANCE = 'GET_ATTENDANCE'
export const GET_NEWS = 'GET_NEWS'
export const GET_ACCOUNT = 'GET_ACCOUNT'
export const GET_DASHBOARD_FEE_STATS = 'GET_DASHBOARD_FEE_STATS'
export const GET_SCHEDULE = 'GET_SCHEDULE'

export const GET_BRANCH_LOGO = 'GET_BRANCH_LOGO'

export const GET_CURRENT_SESSION = 'GET_CURRENT_SESSION'
