import {takeEvery} from 'redux-saga/effects'
import {
  CONFIRM_FILE_UPLOAD,
  GET_SIGNED_UPLOAD_URL,
  GET_TENANT_SESSIONS,
} from 'setup/redux/Actions/Site/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(CONFIRM_FILE_UPLOAD, fetchData)
  yield takeEvery(GET_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(GET_TENANT_SESSIONS, fetchData)
}

export default dataSaga
