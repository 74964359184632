import {FC, Suspense} from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '_metronic/assets/ts/_utils'
import Spinner from './Spinner'

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense
      fallback={
        <>
          <TopBarProgress />
          <div className='d-flex align-items-center justify-content-center h-100 w-100'>
            <Spinner color='primary' />
          </div>
        </>
      }
    >
      {children}
    </Suspense>
  )
}

export default SuspensedView
