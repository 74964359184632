import {takeEvery} from 'redux-saga/effects'
import {
  GET_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
  ADD_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_BY_ID,
  DELETE_PURCHASE_ORDERS_IN_BULK,
  GET_PDF_PURCHASE_ORDER_REPORT,
} from 'setup/redux/Actions/PurchaseOrder/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_PURCHASE_ORDERS, fetchData)
  yield takeEvery(GET_PURCHASE_ORDER, fetchData)
  yield takeEvery(ADD_PURCHASE_ORDER, fetchData)
  yield takeEvery(UPDATE_PURCHASE_ORDER, fetchData)
  yield takeEvery(DELETE_PURCHASE_ORDERS_IN_BULK, fetchData)
  yield takeEvery(DELETE_PURCHASE_ORDER_BY_ID, fetchData)
  yield takeEvery(GET_PDF_PURCHASE_ORDER_REPORT, fetchData)
}

export default dataSaga
