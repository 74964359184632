import {
  GET_PARENT_LIST,
  GET_PARENT_BY_ID,
  GET_EMERGENCY_LIST_ID,
  ADD_PARENT,
  UPDATE_PARENT,
  ADD_EMERGENCY_CONTACTS,
  SMS_PARENT_LOGIN_DETAILS,
  GENERATE_PARENT_LOGIN_REPORT,
  GET_PARENT_CHILDREN_LIST,
  GENERATE_PARENT_SIBLING_REPORT_PDF,
  EMAIL_PARENT_LOGIN_DETAILS,
} from '../../../Actions/School/Parent/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_PARENT_LIST, fetchData)
  yield takeEvery(GET_PARENT_BY_ID, fetchData)
  yield takeEvery(GET_PARENT_CHILDREN_LIST, fetchData)
  yield takeEvery(GET_EMERGENCY_LIST_ID, fetchData)
  yield takeEvery(ADD_PARENT, fetchData)
  yield takeEvery(UPDATE_PARENT, fetchData)
  yield takeEvery(ADD_EMERGENCY_CONTACTS, fetchData)
  yield takeEvery(GENERATE_PARENT_LOGIN_REPORT, fetchData)
  yield takeEvery(SMS_PARENT_LOGIN_DETAILS, fetchData)
  yield takeEvery(EMAIL_PARENT_LOGIN_DETAILS, fetchData)
  yield takeEvery(GENERATE_PARENT_SIBLING_REPORT_PDF, fetchData)
}

export default dataSaga
