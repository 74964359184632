/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState, UserReducer} from 'setup'

import {ErrorsPage} from 'app/modules/errors/ErrorsPage'
import {Logout, AuthPage} from 'app/modules/auth'
import {App} from 'app/App'
import VendorRegisterForm from 'app/pages/school/vendor/components/RegistrationForm'
import FullLayout from 'common/layout/FullLayout'
import SuspensedView from 'common/components/SuspensedView'

const ClientRoutes = lazy(() => import('./ClientRoutes'))
const SchoolRoutes = lazy(() => import('./SchoolRoutes'))

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {isLoggedIn, userType} = useSelector<RootState>(
    (state) => state.userReducer,
    shallowEqual
  ) as UserReducer

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />

          <Route element={<FullLayout />}>
            <Route path='/institute/vendors/register/:id' element={<VendorRegisterForm />} />
          </Route>

          <Route path='auth/*' element={<AuthPage />} />
          {!isLoggedIn && <Route path='*' element={<Navigate to='/auth' />} />}

          {isLoggedIn && <Route path='logout' element={<Logout />} />}

          {isLoggedIn &&
            userType &&
            (userType === 'STUDENT' || userType === 'PARENT' ? (
              <>
                <Route
                  path='/*'
                  element={
                    <SuspensedView>
                      <ClientRoutes />
                    </SuspensedView>
                  }
                />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : userType === 'TEACHER' || userType === 'STAFF' || userType === 'ADMIN' ? (
              <>
                <Route
                  path='/*'
                  element={
                    <SuspensedView>
                      <SchoolRoutes />
                    </SuspensedView>
                  }
                />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <></>
            ))}

          <Route path='*' element={<ErrorsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
