import {takeEvery} from 'redux-saga/effects'
import {
  GET_GOODS_RECEIPT,
  GET_GOODS_RECEIPTS,
  ADD_GOODS_RECEIPTS,
  DELETE_GOODS_RECEIPT_BY_ID,
  DELETE_GOODS_RECEIPT_IN_BULK,
  UPDATE_GOODS_RECEIPTS,
  GOODS_RECEIPT_REPORT,
} from 'setup/redux/Actions/GoodsReceipt/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_GOODS_RECEIPTS, fetchData)
  yield takeEvery(GET_GOODS_RECEIPT, fetchData)
  yield takeEvery(ADD_GOODS_RECEIPTS, fetchData)
  yield takeEvery(UPDATE_GOODS_RECEIPTS, fetchData)
  yield takeEvery(DELETE_GOODS_RECEIPT_BY_ID, fetchData)
  yield takeEvery(DELETE_GOODS_RECEIPT_IN_BULK, fetchData)
  yield takeEvery(GOODS_RECEIPT_REPORT, fetchData)
}

export default dataSaga
