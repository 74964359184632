import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'
import {
  BULK_DATA_CSV,
  LOGS_RECORD,
  TRACK_PROGRESS_BAR,
} from 'setup/redux/Actions/BulkUpload/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(LOGS_RECORD, fetchData)
  yield takeEvery(BULK_DATA_CSV, fetchData)
  yield takeEvery(TRACK_PROGRESS_BAR, fetchData)
}

export default dataSaga
