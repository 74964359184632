export const REDUX_STATE_LOADING = 'REDUX_STATE_LOADING'
export const REDUX_SAVE_SUBJECTS = 'REDUX_SAVE_SUBJECTS'
export const REDUX_SET_HEADER_DROPDOWN = 'REDUX_SET_HEADER_DROPDOWN'
export const REDUX_SELECT_LANGUAGE = 'REDUX_SELECT_LANGUAGE'
export const REDUX_GENERAL_SETTINGS = 'REDUX_GENERAL_SETTINGS'
export const REDUX_SET_BRANCHES = 'REDUX_SET_BRANCHES'
export const REDUX_SET_CUSTOM_TITLES = 'REDUX_SET_CUSTOM_TITLES'
export const REDUX_TENANT_SESSIONS = 'REDUX_TENANT_SESSIONS'
export const REDUX_GET_CLASSES = 'REDUX_GET_CLASSES'
export const REDUX_GET_GRADES = 'REDUX_GET_GRADES'
