import {
  GET_STATS,
  GET_FEED,
  GET_ATTENDANCE,
  GET_ACCOUNT,
  GET_SCHEDULE,
  GET_TOTAL_USER,
  GET_ACTIVE_USER,
  GET_ACTIVITIES_USER,
  GET_NEWS,
  GET_BRANCH_LOGO,
  GET_CURRENT_SESSION,
  GET_DASHBOARD_FEE_STATS,
} from '../../Actions/Dashboard/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_STATS, fetchData)
  yield takeEvery(GET_FEED, fetchData)

  yield takeEvery(GET_ACTIVE_USER, fetchData)
  yield takeEvery(GET_ACTIVITIES_USER, fetchData)
  yield takeEvery(GET_TOTAL_USER, fetchData)
  yield takeEvery(GET_ATTENDANCE, fetchData)
  yield takeEvery(GET_NEWS, fetchData)
  yield takeEvery(GET_ACCOUNT, fetchData)
  yield takeEvery(GET_DASHBOARD_FEE_STATS, fetchData)
  yield takeEvery(GET_SCHEDULE, fetchData)

  yield takeEvery(GET_BRANCH_LOGO, fetchData)

  yield takeEvery(GET_CURRENT_SESSION, fetchData)
}

export default dataSaga
