export const ADD_DEVICE_TOKEN = 'ADD_DEVICE_TOKEN'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_CHILD_PROFILE = 'GET_CHILD_PROFILE'
export const GET_USER_MESSAGES = 'GET_USER_MESSAGES'
export const GET_ACTIVE_STAFF = 'GET_ACTIVE_STAFF'
export const GET_PROFILE_PICTURE_UPLOAD_URL = 'GET_PROFILE_PICTURE_UPLOAD_URL'
export const GET_PROFILE_PICTURE_UPLOAD_URL_ANY_USER = 'GET_PROFILE_PICTURE_UPLOAD_URL_ANY_USER'
export const CONFIRM_PROFILE_PICTURE_UPLOAD = 'CONFIRM_PROFILE_PICTURE_UPLOAD'
export const CONFIRM_PROFILE_PICTURE_UPLOAD_ANY_USER = 'CONFIRM_PROFILE_PICTURE_UPLOAD_ANY_USER'
export const GET_SMS_SERVICES = 'GET_SMS_SERVICES'
export const GET_ALL_ACTIVE_EMPLOYEE_ATTENDANCE = 'GET_ALL_ACTIVE_EMPLOYEE_ATTENDANCE'
export const GET_ALL_ACTIVE_STUDENTS_ATTENDANCE = 'GET_ALL_ACTIVE_STUDENTS_ATTENDANCE'
export const GET_STUDENTS_SUBJECT_ATTENDANCE = 'GET_STUDENTS_SUBJECT_ATTENDANCE'
export const GET_TEACHERS_SUBJECT_ATTENDANCE = 'GET_TEACHERS_SUBJECT_ATTENDANCE'
export const ACTIVATE_USERS = 'ACTIVATE_USERS'
export const INACTIVATE_USERS = 'INACTIVATE_USERS'
export const MOVE_USERS_TO_JUNK = 'MOVE_USERS_TO_JUNK'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const USER_IMPERSONATE = 'USER_IMPERSONATE'
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
