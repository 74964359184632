import {
  GET_ITEM_LIST,
  DELETE_ITEM_BY_ID,
  GET_ITEM_BY_ID,
  UPDATE_ITEM,
  ADD_ITEM,
} from '../../../Actions/ItemCategory/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_ITEM_LIST, fetchData)
  yield takeEvery(DELETE_ITEM_BY_ID, fetchData)
  yield takeEvery(GET_ITEM_BY_ID, fetchData)
  yield takeEvery(UPDATE_ITEM, fetchData)
  yield takeEvery(ADD_ITEM, fetchData)
}

export default dataSaga
