import {
  GET_BRANCHES,
  DELETE_BRANCH,
  ADD_BRANCH,
  VIEW_BRANCH,
  UPDATE_BRANCH,
  GET_BRANCH_LOGO_SIGNED_UPLOAD_URL,
  CONFIRM_BRANCH_LOGO_FILE_UPLOAD,
  GET_BRANCH_BANNER_SIGNED_UPLOAD_URL,
  CONFIRM_BRANCH_BANNER_FILE_UPLOAD,
  DELETE_BRANCH_LOGO_FILE,
  DELETE_BRANCH_BANNER_FILE,
  CHECK_BRANCH_LIMIT,
} from '../../Actions/Branch/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_BRANCHES, fetchData)
  yield takeEvery(DELETE_BRANCH, fetchData)
  yield takeEvery(ADD_BRANCH, fetchData)
  yield takeEvery(VIEW_BRANCH, fetchData)
  yield takeEvery(UPDATE_BRANCH, fetchData)
  yield takeEvery(GET_BRANCH_LOGO_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(CONFIRM_BRANCH_LOGO_FILE_UPLOAD, fetchData)
  yield takeEvery(GET_BRANCH_BANNER_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(CONFIRM_BRANCH_BANNER_FILE_UPLOAD, fetchData)
  yield takeEvery(DELETE_BRANCH_LOGO_FILE, fetchData)
  yield takeEvery(DELETE_BRANCH_BANNER_FILE, fetchData)
  yield takeEvery(CHECK_BRANCH_LIMIT, fetchData)
}

export default dataSaga
