import {FORGOT_PASSWORD, RESET_PASSWORD} from '../../Actions/SignIn/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(FORGOT_PASSWORD, fetchData)
  yield takeEvery(RESET_PASSWORD, fetchData)
}

export default dataSaga
