export const GET_VENDORS = 'GET_VENDORS'
export const GET_VENDOR = 'GET_VENDOR'
export const ADD_VENDOR = 'ADD_VENDOR'
export const UPDATE_VENDOR = 'UPDATE_VENDOR'
export const DELETE_VENDOR = 'DELETE_VENDOR'
export const REGISTER_VENDOR = 'REGISTER_VENDOR'
export const GET_VENDOR_SIGNED_UPLOAD_URL = 'GET_VENDOR_SIGNED_UPLOAD_URL'
export const DELETE_VENDOR_OLD_CNIC_FILES = 'DELETE_VENDOR_OLD_CNIC_FILES'
export const CONFIRM_VENDOR_FILE_UPLOAD = 'CONFIRM_VENDOR_FILE_UPLOAD'

export const GET_VENDOR_SETTINGS = 'GET_VENDOR_SETTINGS'
export const UPDATE_VENDOR_SETTINGS = 'UPDATE_VENDOR_SETTINGS'
