export const GET_SCHEDULE_YEARLY_CALENDAR = 'GET_SCHEDULE_YEARLY_CALENDAR'
export const GET_SCHEDULE_DAILY_CALENDAR = 'GET_SCHEDULE_DAILY_CALENDAR'
export const GET_SCHEDULE_YEARLY_LIST = 'GET_SCHEDULE_YEARLY_LIST'
export const DELETE_YEARLY_SCHEDULE = 'DELETE_YEARLY_SCHEDULE'
export const GET_SCHEDULE_YEARLY_BY_ID = 'GET_SCHEDULE_YEARLY_BY_ID'
export const UPDATE_YEARLY_SCHEDULE = 'UPDATE_YEARLY_SCHEDULE'
export const GET_SCHEDULE_DAILY_LIST = 'GET_SCHEDULE_DAILY_LIST'
export const DELETE_DAILY_SCHEDULE = 'DELETE_DAILY_SCHEDULE'
export const DELETE_MANY_DAILY_SCHEDULE = 'DELETE_MANY_DAILY_SCHEDULE'
export const GET_SCHEDULE_DAILY_BY_ID = 'GET_SCHEDULE_DAILY_BY_ID'
export const UPDATE_DAILY_SCHEDULE = 'UPDATE_DAILY_SCHEDULE'
export const ADD_YEARLY_SCHEDULE = 'ADD_YEARLY_SCHEDULE'
export const DELETE_DAILY_SCHEDULE_CLASSES = 'DELETE_DAILY_SCHEDULE_CLASSES'
export const GET_SCHEDULE_CLASS_LIST = 'GET_SCHEDULE_CLASS_LIST'
export const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM'
export const ADD_CLASSROOM = 'ADD_CLASSROOM'
export const GET_CLASSROOM_BY_ID = 'GET_CLASSROOM_BY_ID'
export const ADD_DAILY_SCHEDULE = 'ADD_DAILY_SCHEDULE'
export const ADD_EXTRACLASS = 'ADD_EXTRACLASS'
