import clsx from 'clsx'
import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  name: string
  value: string | number
  label: string
  className?: string
  required?: boolean
  showError?: boolean
  title?: string
}

const CustomRadio: FC<Props> = ({
  name,
  value,
  label,
  className = '',
  required = false,
  showError = false,
  title,
}) => {
  const intl = useIntl()
  const id = `${name}-${Math.random()}`
  const labelHasTranslation = !!intl.messages[label]
  const titleHasTranslation = title ? !!intl.messages[title] : false
  return (
    <>
      <div className={clsx('form-check mb-2', className)}>
        <Field id={id} className='form-check-input' name={name} type='radio' value={value} />

        {title ? (
          <OverlayTrigger
            placement='left'
            overlay={
              <Tooltip id={`tooltip-${Math.random()}`}>
                {titleHasTranslation ? intl.formatMessage({id: label}) : title}
              </Tooltip>
            }
          >
            <label
              className={`form-check-label text-gray-800 ${required ? 'required' : ''}`}
              htmlFor={id}
            >
              {labelHasTranslation ? intl.formatMessage({id: label}) : label}
            </label>
          </OverlayTrigger>
        ) : (
          <label
            className={`form-check-label text-gray-800 ${required ? 'required' : ''}`}
            htmlFor={id}
          >
            {labelHasTranslation ? intl.formatMessage({id: label}) : label}
          </label>
        )}
      </div>
      {showError && <ErrorMessage component='div' name={name} className='text-danger mb-3' />}
    </>
  )
}

export default CustomRadio
